import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { decimalValidation, required } from '../../../utils/validations';
import { fetcher } from '../../../services/api';
import { InputWrapper } from '../../common/input-wrapper';
import { FormattedMessage, useIntl } from 'react-intl';
import { Select } from '../../common/select.component';
import { useSuppliers } from '../../../api/suppliers';
import useSWR from 'swr';
import {
  CleanDeliveryMethod,
  CleanDeliveryOrderDeliveryMarkup,
} from '../../../export-types/cleaned-types';
import { useLocalDeliveryMethods } from '../../../api/delivery';
import { uniqBy } from 'lodash';
import { ModalProps } from '../../../services/modals.service';
import { useModalClose } from '../../../utils/useModalClose';

type AutoSendParametersEditModalProps = ModalProps & {
  defaultValues?: Form;
  close: (resolve: Form) => void;
};

type Form = {
  count: number;
  id?: number;
  weight: string;
  volume: string;
  price: string;
  deliveryMethod?: string;
  localDeliveryMethodId?: string;
  supplierId?: number;
};

export const AutoSendParametersEditModal: React.FC<
  AutoSendParametersEditModalProps
> = (props) => {
  const { data: suppliers } = useSuppliers();
  const { data: localDeliveryMethods } = useLocalDeliveryMethods();
  const { data: deliveryMethods } = useSWR<Array<CleanDeliveryMethod>>(
    '/delivery-method',
    fetcher,
  );
  const { formState, handleSubmit, control, watch } = useForm<Form>({
    defaultValues: props.defaultValues,
  });
  const supplierId = watch('supplierId');
  const filteredDeliveryMethods = React.useMemo(() => {
    return uniqBy(
      deliveryMethods
        ?.filter(
          (deliveryMethod) =>
            !deliveryMethod.disabled &&
            deliveryMethod?.supplier?.id === supplierId,
        )
        .map(({ name }) => ({
          value: name,
          label: name,
        })) ?? [],
      'value',
    );
  }, [deliveryMethods, supplierId]);

  const filteredLocalDeliveryMethods = React.useMemo(() => {
    return (
      localDeliveryMethods
        ?.filter(
          (deliveryMethod) =>
            !deliveryMethod.disabled &&
            deliveryMethod?.supplier?.id === supplierId,
        )
        .map(({ id, name }) => ({
          value: id,
          label: name,
        })) ?? []
    );
  }, [localDeliveryMethods, supplierId]);

  const intl = useIntl();

  const onSubmit = handleSubmit(async (data) => {
    props.close(data);
  });

  const modalRef = useModalClose(props.reject);

  return (
    <div className="modal-dialog" ref={modalRef}>
      <form className="modal-content" onSubmit={onSubmit}>
        <div className="modal-header border-bottom-0 p-4">
          <h3>
            <FormattedMessage
              defaultMessage="Изменить параметры авто-отправки"
              id="pw1SIC"
              description="Заголовок модального окна"
            />
          </h3>
          <button
            type="button"
            className="btn-close"
            onClick={() => props.reject()}
          />
        </div>

        <div className="modal-body pt-2 p-4">
          <Controller
            control={control}
            name="price"
            rules={{ ...required, ...decimalValidation }}
            render={({ field, fieldState }) => (
              <InputWrapper
                title={intl.formatMessage({
                  defaultMessage: 'Цена',
                  id: 'ryU78V',
                  description: 'Заголовок поля ввода',
                })}
                className="mb-3"
                error={fieldState.error?.message}
                required
              >
                <div className="input-group">
                  <input
                    className="form-control"
                    type="number"
                    min={1}
                    step={1}
                    {...field}
                  />
                  <span className="input-group-text">¥</span>
                </div>
              </InputWrapper>
            )}
          />
          <div className="mt-3">
            <div className="row align-items-end">
              <div className="col-4">
                <Controller
                  control={control}
                  name="count"
                  rules={{ ...required, ...decimalValidation }}
                  render={({ field, fieldState }) => (
                    <InputWrapper
                      title={intl.formatMessage({
                        defaultMessage: 'Кол-во товара',
                        id: 'LEG1wx',
                        description: 'Заголовок поля ввода',
                      })}
                      error={fieldState.error?.message}
                      required
                    >
                      <div className="input-group">
                        <input
                          className="form-control"
                          type="number"
                          min={1}
                          step={1}
                          {...field}
                        />
                        <span className="input-group-text">
                          <FormattedMessage
                            defaultMessage="шт"
                            id="OINxG3"
                            description="Единица измерения"
                          />
                        </span>
                      </div>
                    </InputWrapper>
                  )}
                />
              </div>
              <div className="col-4">
                <Controller
                  control={control}
                  name="weight"
                  rules={{ ...required, ...decimalValidation }}
                  render={({ field, fieldState }) => (
                    <InputWrapper
                      theme="light"
                      title={intl.formatMessage({
                        defaultMessage: 'Вес',
                        id: 'avUEaD',
                        description: 'Заголовок поля ввода',
                      })}
                      error={fieldState.error?.message}
                      required
                    >
                      <div className="input-group">
                        <input
                          className="form-control"
                          type="number"
                          min={0}
                          step={0.01}
                          {...field}
                        />
                        <span className="input-group-text">
                          <FormattedMessage
                            defaultMessage="кг"
                            id="TJq7iV"
                            description="Единица измерения"
                          />
                        </span>
                      </div>
                    </InputWrapper>
                  )}
                />
              </div>
              <div className="col-4">
                <Controller
                  control={control}
                  name="volume"
                  rules={{ ...required, ...decimalValidation }}
                  render={({ field, fieldState }) => (
                    <InputWrapper
                      theme="light"
                      title={intl.formatMessage({
                        defaultMessage: 'Объём',
                        id: '2D4FVk',
                        description: 'Заголовок поля ввода информации',
                      })}
                      error={fieldState.error?.message}
                      required
                    >
                      <div className="input-group">
                        <input
                          className="form-control"
                          type="number"
                          min={0}
                          step={0.01}
                          {...field}
                        />
                        <span className="input-group-text">
                          <FormattedMessage
                            defaultMessage="м³"
                            id="9DUMqJ"
                            description="Единица измерения"
                          />
                        </span>
                      </div>
                    </InputWrapper>
                  )}
                />
              </div>
            </div>
          </div>
          <Controller
            name="supplierId"
            control={control}
            rules={required}
            render={({ field, fieldState }) => (
              <Select
                className="mt-3"
                title="Поставщик"
                placeholder="Выберите поставщика"
                options={
                  suppliers?.map(({ id, name, location }) => ({
                    value: id,
                    label:
                      name +
                      (location?.name ? ` (откуда: ${location.name})` : ''),
                  })) ?? []
                }
                {...field}
                error={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name="deliveryMethod"
            control={control}
            rules={required}
            render={({ field, fieldState }) => (
              <Select
                className="mt-3"
                title="Способ доставки"
                placeholder="Выберите способ доставки"
                options={filteredDeliveryMethods}
                {...field}
                error={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name="localDeliveryMethodId"
            control={control}
            rules={required}
            render={({ field, fieldState }) => (
              <Select
                className="mt-3"
                title="Транспортная компания"
                placeholder="Выберите транспортную компанию"
                options={filteredLocalDeliveryMethods}
                {...field}
                error={fieldState.error?.message}
              />
            )}
          />
        </div>
        <div className="modal-footer justify-content-end">
          <button
            type="submit"
            className="btn btn-success"
            disabled={formState.isSubmitting}
          >
            {formState.isSubmitting ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              'Обновить'
            )}
          </button>
        </div>
      </form>
    </div>
  );
};
