import { usePurchaseOrders } from '../../../api/use-purchase-orders';
import { getPrefixByType } from '../../common/cards/id-cell-card.component';
import { Link } from 'react-router-dom';
import { usePaginationQs } from '../../../utils/usePagination';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
type Props = {
  user: number | null;
};
export const PurchaseOrderConfirmationModalClientOrders = ({ user }: Props) => {
  const [anchorIsOpen, setAnchorIsOpen] = useState(false);
  const toggleAnchor = () => setAnchorIsOpen((prev) => !prev);
  const { take, skip } = usePaginationQs(100);

  let debouncedInternalId = '';
  let selectSupplierId = 0;
  const { data } = usePurchaseOrders({
    take,
    skip,
    status: ['receivedInStock'],
    internalId: debouncedInternalId || undefined,
    supplierId: selectSupplierId || undefined,
  });
  const [prefix] = getPrefixByType();
  const dataFilter = data?.items.filter(
    (order) => order.user?.internalId == user,
  );
  const dataLength = dataFilter?.length ?? 0;

  return (
    <>
      {dataLength > 0 && (
        <button
          type="button"
          className="d-flex gap-2 align-items-center list-group-item w-100 color-green p-0 ms-4 me-4 mb-4"
          onClick={toggleAnchor}
        >
          <p className="m-0">
            <FormattedMessage
              defaultMessage=" Товары Клиента на складе({value})"
              id="purchaseOrderConfirmationModalClientOrders.label.сlient'sGoodsInTheWarehouse"
              description="Информация в модальном окне"
              values={{
                value: dataLength,
              }}
            />
          </p>
          {anchorIsOpen ? (
            <i className="bi bi-chevron-up"></i>
          ) : (
            <i className="bi bi-chevron-down"></i>
          )}
        </button>
      )}
      {anchorIsOpen && (
        <div className="col">
          <ul className="list-unstyled p-0 m-4 mt-0" id="client-orders">
            {dataFilter?.map((order) => (
              <li
                className="w-100 d-grid grid-cols-3 p-3 background-gray rounded-3 bg-gray mt-1"
                key={order.id}
                style={{ gridTemplateColumns: '1fr 1fr 1fr' }}
              >
                <Link
                  to={`/purchases/${order.id}`}
                  className="d-inline-block"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p className="m-0 transition-all-200 mr-auto">
                    {prefix}
                    {order.internalId}
                  </p>
                </Link>
                {order.storageLocation && (
                  <div className="color-gray-800 fs-16 w-100">
                    <FormattedMessage
                      defaultMessage=" Место: {value}"
                      id="purchaseOrderConfirmationModalClientOrders.label.storage"
                      description="Место хранения"
                      values={{
                        value: order.storageLocation,
                      }}
                    />
                  </div>
                )}
                <div className="gap-3 ml-1">
                  <p className="m-0" style={{ textAlign: 'right' }}>
                    <FormattedMessage
                      defaultMessage="{value} м³"
                      id="input.label.length"
                      description="Единица измерения"
                      values={{
                        value: order.volume,
                      }}
                    />
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};
