import { RenderProps } from './delivery-orders-tab';
import React from 'react';
import { PaidStatus } from '../../common/paid-status.component';
import classNames from 'classnames';
import tableStyle from './delivery-order-item.module.css';
import { IdCell } from '../../common/cards/id-cell-card.component';
import { UserInternalIdCell } from '../../common/cards/id-user-cell-card.component';
import { TariffCell } from '../../common/cards/tariff-cell-card.component';
import { LocalDelivery } from '../../common/cards/local-delivery-cell-card.component';
import { toMoney } from '../../../utils/common.utils';
import { ImagesCell } from '../../common/cards/images-cell-card.component';
import { DeliveryPeriodCell } from '../../common/cards/delivery-date-cell-card.component';
import { WeightVolumeCell } from '../../common/cards/weight-volume-cell-card.component';
import { PriceCell } from '../../common/cards/price-cell-card.component';
import moment from 'moment';
import { TrackNumberCell } from '../../common/cards/track-number-cell-card.component';
import { NameCell } from '../../common/cards/name-cell-card.component';
import { Rbac, useRbac } from '../../common/Rbac';
import { RbacObject } from '../../../export-types/cleaned-types';
import { BlockStatus } from '../../common/block-status.component';
import { Manager } from '../../common/cards/manager-cell-card.component';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

export function AdminDeliveryOrderItem({ ...props }: RenderProps) {
  const { isLoading, hasPermission } = useRbac();

  const canReadExtendedData =
    !isLoading &&
    hasPermission(RbacObject.DeliveryOrder, 'read:finances-client');

  const ItemPaidStatus = React.useMemo(() => {
    if (!props.data.price)
      return <PaidStatus className="ms-3" type="preliminaryPrice" />;

    if (!!props.data.price && !props.data.paid)
      return <PaidStatus className="ms-3" type="waiting" />;

    if (props.data.paid) return <PaidStatus className="ms-3" type="paid" />;
  }, [props.data.paid, props.data.price]);

  const ExpandCell = () => (
    <button
      type="button"
      className="btn d-flex flex-row justify-content-center"
      onClick={(e) => {
        e.stopPropagation();
        props.onHideClick(props.data);
      }}
    >
      {!props.collapsed ? (
        <i className={`bi bi-chevron-up`} />
      ) : (
        <i className={`bi bi-chevron-down`} />
      )}
    </button>
  );

  return (
    <div className={classNames('row g-0 mb-3', tableStyle.rowContainer)}>
      <div
        className={classNames(
          'position-relative row cursor-pointer g-0',
          tableStyle.tableRow,
        )}
        onClick={() => props.onClick(props.data)}
      >
        {(props.data?.unreadMessages ?? 0) > 0 && (
          <div className="position-absolute top-0 start-0">
            <span className="badge bg-green-500">
              {props.data.unreadMessages}
            </span>
          </div>
        )}
        {canReadExtendedData && (
          <Rbac object={RbacObject.DeliveryOrder} action="read:finances-client">
            <div className="col-1">{props.data.supplier?.name ?? ''}</div>
          </Rbac>
        )}
        {(props.status === 'assembly' || props.status === 'processing') && (
          <>
            <div className="col pe-1">
              <Link
                to={`/deliveries/delivery-order/${props.data.id}`}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <IdCell internalId={props.data.internalId} type="delivery" />
              </Link>
            </div>
            <div className="col">
              <UserInternalIdCell user={props.data.user} />
            </div>
            <div className="col">
              <ExpandCell />
            </div>
            <div className="col-3">
              <TariffCell deliveryMethod={props.data.deliveryMethod!} />
            </div>
            {canReadExtendedData ? (
              <div className="col-2">
                <LocalDelivery localDelivery={props.data.localDeliveryMethod} />
              </div>
            ) : null}
            {canReadExtendedData && props.data.user?.manager ? (
              <div className="col-1">
                <Manager manager={props.data.user?.manager} />
              </div>
            ) : (
              <div className="col-1"></div>
            )}
            <div className="col-sm-2 col-xl-3 text-end">
              <div className="">
                <div className="color-gray-800 fs-16 fw-500  text-truncate">
                  {toMoney(
                    props.data.moderateProductPrice!,
                    props.data.supplier?.purchaseCurrency || 'cny',
                  )}
                </div>
                <div className="color-gray-600 fs-14 fw-400">
                  <FormattedMessage
                    defaultMessage="Стоимость товаров"
                    id="delivery.label.priceOfGoods"
                    description="Стоимость товаров"
                  />
                </div>
              </div>
            </div>
          </>
        )}
        {props.status === 'sentToRussia' && (
          <>
            <div className="col pe-1">
              <Link
                to={`/deliveries/delivery-order/${props.data.id}`}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <IdCell internalId={props.data.internalId} type="delivery" />
              </Link>
            </div>
            <div className="col">
              <UserInternalIdCell user={props.data.user} />
            </div>
            <div className="col">
              <ExpandCell />
            </div>
            <div
              className={classNames(
                canReadExtendedData ? 'col-2' : 'col-3',
                'd-flex',
              )}
              onClick={(e) => e.stopPropagation()}
            >
              <ImagesCell imagesList={props.data.packedFilesV2} />
            </div>
            <div className="col-sm-2 col-xl-3 d-flex align-items-center">
              <DeliveryPeriodCell
                deliveryMethod={props.data.deliveryMethod!}
                sentDate={props.data.sentDate!}
              />
            </div>
            {canReadExtendedData ? (
              <div className="col-1">
                <LocalDelivery localDelivery={props.data.localDeliveryMethod} />
              </div>
            ) : null}
            <div
              className={classNames(
                canReadExtendedData ? 'col-1' : 'col-2 text-end',
              )}
            >
              <WeightVolumeCell
                weight={props.data.weight}
                volume={props.data.volume}
              />
            </div>
            {canReadExtendedData && props.data.user?.manager ? (
              <div className="col-1">
                <Manager manager={props.data.user?.manager} />
              </div>
            ) : (
              <div className="col-1"></div>
            )}
            {canReadExtendedData ? (
              <div className="col-1 justify-content-end d-flex align-items-center">
                <PriceCell price={props.data.price!} currency={'usd'} />
                {ItemPaidStatus}
              </div>
            ) : null}
          </>
        )}
        {['receivedInRussia', 'receivedInRetailRussia'].includes(
          props.data.status,
        ) && (
          <>
            <div className="col pe-1">
              <Link
                to={`/deliveries/delivery-order/${props.data.id}`}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <IdCell internalId={props.data.internalId} type="delivery" />
              </Link>
            </div>
            <div className="col">
              <UserInternalIdCell user={props.data.user} />
            </div>
            <div className="col">
              <ExpandCell />
            </div>
            <div
              className={classNames(
                canReadExtendedData ? 'col' : 'col-2',
                'd-flex',
              )}
              onClick={(e) => e.stopPropagation()}
            >
              <ImagesCell imagesList={props.data.packedFilesV2} />
            </div>
            <div
              className={classNames(canReadExtendedData ? 'col-1' : 'col-2')}
            >
              <WeightVolumeCell
                weight={props.data.weight}
                volume={props.data.volume}
              />
            </div>
            <div
              className={classNames(
                'col-2 ps-2',
                canReadExtendedData ? 'text-start' : '',
              )}
            >
              <div className="color-gray-800 fs-16 fw-500">
                {moment(props.data.receiveRfDate).format('DD.MM.YYYY')}
              </div>
              <div className="color-gray-600 fs-14">
                <FormattedMessage
                  defaultMessage="Получено"
                  id="order.status-label.received"
                  description="Статус во вкладке"
                />
              </div>
            </div>
            <div
              className={classNames(
                'col-2',
                canReadExtendedData ? '' : 'text-end',
              )}
            >
              <LocalDelivery localDelivery={props.data.localDeliveryMethod} />
            </div>
            {canReadExtendedData && props.data.user?.manager ? (
              <div className="col-1">
                <Manager manager={props.data.user?.manager} />
              </div>
            ) : (
              <div className="col-1"></div>
            )}
            {canReadExtendedData ? (
              <div className="col-2 justify-content-end d-flex align-items-center">
                <PriceCell price={props.data.price!} currency={'usd'} />
                {ItemPaidStatus}
              </div>
            ) : null}
          </>
        )}
        {props.status === 'sentToRecipient' && (
          <>
            <div className="col pe-1">
              <Link
                to={`/deliveries/delivery-order/${props.data.id}`}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <IdCell internalId={props.data.internalId} type="delivery" />
              </Link>
            </div>
            <div className="col">
              <UserInternalIdCell user={props.data.user} />
            </div>
            <div className="col">
              <ExpandCell />
            </div>
            <div
              className={classNames(
                canReadExtendedData ? 'col' : 'col-2',
                'd-flex',
              )}
              onClick={(e) => e.stopPropagation()}
            >
              <ImagesCell imagesList={props.data.packedFilesV2} />
            </div>
            <div
              className={classNames(
                canReadExtendedData ? 'col-2 text-start ' : 'col-3',
              )}
            >
              <div className="color-gray-800 fs-16 fw-500">
                {moment(props.data.receiveRfDate).format('DD.MM.YYYY')}
              </div>
              <div className="color-gray-600 fs-14">
                <FormattedMessage
                  defaultMessage="Передано в ТК"
                  id="deliveryOrder.status-label.transferredToTheShippingCompany"
                  description="Статус доставки"
                />
              </div>
            </div>
            <div className="col-2">
              <LocalDelivery localDelivery={props.data.localDeliveryMethod} />
            </div>
            <div
              className={classNames(
                'col-2',
                canReadExtendedData ? '' : 'text-end',
              )}
            >
              {props.data.trackNumber && (
                <TrackNumberCell trackNumber={props.data.trackNumber} />
              )}
            </div>
            {canReadExtendedData && props.data.user?.manager ? (
              <div className="col-1">
                <Manager manager={props.data.user?.manager} />
              </div>
            ) : (
              <div className="col-1"></div>
            )}
            {canReadExtendedData ? (
              <div className="col-1 justify-content-end d-flex align-items-center">
                {canReadExtendedData && (
                  <>
                    <PriceCell price={props.data.price!} currency={'usd'} />
                    {ItemPaidStatus}
                  </>
                )}
                {props.data.blocked && (
                  <BlockStatus
                    blocked={props.data.blocked!}
                    className="ms-3"
                    label="Заблокировано в ТК"
                  />
                )}
              </div>
            ) : null}
          </>
        )}
        {props.status === 'delivered' && (
          <>
            <div className="col pe-1">
              <Link
                to={`/deliveries/delivery-order/${props.data.id}`}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <IdCell internalId={props.data.internalId} type="delivery" />
              </Link>
            </div>
            <div className="col">
              <UserInternalIdCell user={props.data.user} />
            </div>
            <div className="col">
              <ExpandCell />
            </div>
            <div className="col-5 d-flex" onClick={(e) => e.stopPropagation()}>
              <ImagesCell imagesList={props.data.packedFilesV2} />
            </div>
            <div className="col-sm-3 col-xl-4 text-end">
              <div className="color-gray-800 fs-16 fw-500">
                {moment(props.data.deliveredDate).format('DD.MM.YYYY')}
              </div>
              <div className="color-gray-600 fs-14">
                <FormattedMessage
                  defaultMessage="Получено"
                  id="order.status-label.received"
                  description="Статус во вкладке"
                />
              </div>
            </div>
          </>
        )}
      </div>
      {!props.collapsed &&
        props.data.purchaseOrders?.map((purchaseOrder) => (
          <div
            className={classNames(
              'row cursor-pointer g-0',
              tableStyle.purchaseTableRow,
            )}
            key={purchaseOrder.id}
            onClick={() => props.onPurchaseRowClick(purchaseOrder)}
          >
            <div className="col pe-1">
              <Link
                to={
                  purchaseOrder.type === 'purchase'
                    ? `/purchases/${purchaseOrder.id}`
                    : `/parcels/${purchaseOrder.id}`
                }
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <IdCell
                  internalId={purchaseOrder.internalId}
                  type={purchaseOrder.type}
                />
              </Link>
            </div>
            <div className="col">
              <ImagesCell imagesList={purchaseOrder.requestFilesV2} />
            </div>
            <div className="col-8">
              <NameCell name={purchaseOrder.name} />
            </div>
            {props.status === 'assembly' && (
              <div className="col-1">
                <NameCell name={purchaseOrder.storageLocation!} />
              </div>
            )}
            <div className="col-1 text-end">
              {purchaseOrder.count && (
                <FormattedMessage
                  defaultMessage="{value} шт"
                  id="inputValue.label.pcs"
                  description="Единица измерения"
                  values={{ value: purchaseOrder.count }}
                />
              )}
            </div>
          </div>
        ))}
    </div>
  );
}
