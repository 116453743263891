import React from 'react';
import { InputWrapper } from '../../common/input-wrapper';
import { Gallery } from '../../common/gallery/gallery';
import { getFileV2ApiUrl } from '../../../utils/common.utils';
import { useOrderCategories } from '../../../consts/db-values';
import useSWR from 'swr';
import { fetcher } from '../../../services/api';
import { UploadedFileV2 } from '../../../services/file-service';
import { useProfile } from '../../../api/profile';
import { FormattedMessage, useIntl } from 'react-intl';
import { RbacSome } from '../../common/Rbac';
import { RbacObject } from '../../../export-types/cleaned-types';
import { ReceivedEditForm } from './received-edit-form';

type PurchaseOrderCategory =
  | 'householdGoods'
  | 'smallHouseholdGoods'
  | 'clothes';

export type PurchaseOrder = {
  id: string;
  status:
    | 'waitingForPayment'
    | 'inWork'
    | 'onTheWayToStock'
    | 'receivedInStock'
    | 'sentToRussia'
    | 'canceled';
  productFilesV2: UploadedFileV2[];
  category?: PurchaseOrderCategory;
  weight?: string;
  volume?: string;
  count?: number;
  places?: number;
  storageLocation?: string;
  receiptComment?: string;
  processingFromTerminal?: boolean;
};

type ReceiptConfirmationProps = {
  id: string;
};
export const ReceiptConfirmation: React.FC<ReceiptConfirmationProps> = (
  props,
) => {
  const intl = useIntl();
  const ORDER_CATEGORIES = useOrderCategories();
  const { data: user } = useProfile();
  const { data: order, mutate: orderMutate } = useSWR<PurchaseOrder>(
    `/purchase-order/${props.id}`,
    fetcher,
  );

  const [editMode, setEditMode] = React.useState(false);

  if (!order || !user) {
    return null;
  }

  function handleSave(data: PurchaseOrder) {
    orderMutate(data);
    setEditMode(false);
  }

  function handleEdit() {
    setEditMode(true);
  }

  function handleCancel() {
    setEditMode(false);
  }

  if (editMode) {
    return (
      <ReceivedEditForm
        id={order.id}
        defaultValues={{
          productFiles: order.productFilesV2.map((file) => ({
            type: 'exist',
            file,
          })),
          category: order.category,
          weight: order.weight,
          volume: order.volume,
          count: order.count,
          places: order.places,
          storageLocation: order.storageLocation,
          receiptComment: order.receiptComment,
        }}
        onSave={handleSave}
        onCancel={handleCancel}
      />
    );
  }

  return (
    <div>
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="fs-18 fw-600 color-gray-450">
          <FormattedMessage
            defaultMessage="Получение на склад"
            id="receiptConfirmation.label.receiptAtTheWarehouse"
            description="Заголовок на странице"
          />
          {order.processingFromTerminal && <i className="mx-1 bi-tablet" />}
        </div>
        {order.status !== 'canceled' && (
          <RbacSome
            checks={[
              { object: RbacObject.Purchase, action: 'confirmReceipt' },
              { object: RbacObject.Purchase, action: 'write:receipt-data' },
            ]}
          >
            <button
              className="btn btn-link text-decoration-none"
              type="button"
              onClick={handleEdit}
            >
              Изменить
            </button>
          </RbacSome>
        )}
      </div>
      {order.productFilesV2.length > 0 && (
        <InputWrapper
          theme="light"
          className="mt-3"
          title={intl.formatMessage({
            defaultMessage: 'Фотографии товаров при получении',
            id: 'input.label.photosOfGoodsOnReceipt',
            description: 'Заголовок поля ввода',
          })}
        >
          <Gallery className="mt-2">
            {order.productFilesV2.map((file, index) => (
              <a href={getFileV2ApiUrl(file.id)} key={index}>
                <img
                  className="rounded fit-cover me-2"
                  src={getFileV2ApiUrl(file.id, '64x64')}
                  width={64}
                  height={64}
                  alt=""
                />
              </a>
            ))}
          </Gallery>
        </InputWrapper>
      )}
      {order.category && (
        <InputWrapper
          theme="light"
          className="mt-3 text-truncate"
          title={intl.formatMessage({
            defaultMessage: 'Категория товара',
            id: 'input.input-label.goodsCategory',
            description: 'Заголовок поля ввода',
          })}
        >
          <div>{ORDER_CATEGORIES[order.category]}</div>
        </InputWrapper>
      )}
      {order.weight && order.volume && (
        <div className="mt-3">
          <div className="row">
            <div className="col-6">
              <InputWrapper
                theme="light"
                title={intl.formatMessage({
                  defaultMessage: 'Вес при получении',
                  id: 'receiptConfirmation.input-label.weightUponReceipt',
                  description: 'Заголовок поля ввода',
                })}
              >
                <div>
                  {order.weight}
                  <FormattedMessage
                    defaultMessage="кг"
                    id="input.label.kg"
                    description="Единица измерения"
                  />
                </div>
              </InputWrapper>
            </div>
            <div className="col-6 ">
              <InputWrapper
                theme="light"
                title={intl.formatMessage({
                  defaultMessage: 'Объем при получении',
                  id: 'receiptConfirmation.input-label.volumeUponReceipt',
                  description: 'Заголовок поля ввода',
                })}
              >
                <div>
                  {order.volume}
                  <FormattedMessage
                    defaultMessage="м³"
                    id="input.label.length"
                    description="Единица измерения"
                  />
                </div>
              </InputWrapper>
            </div>
          </div>
        </div>
      )}

      <div className="mt-3">
        <div className="row">
          {order.places && (
            <div className="col-6">
              <InputWrapper
                theme="light"
                title={intl.formatMessage({
                  defaultMessage: 'Количество мест',
                  id: 'deliveryAssemblyModal.input-label.amountOfPlaces',
                  description: 'Заголовок поля ввода',
                })}
              >
                <div>{order.places}</div>
              </InputWrapper>
            </div>
          )}
          {order.count && (
            <div className="col-6">
              <InputWrapper
                theme="light"
                title={intl.formatMessage({
                  defaultMessage: 'Общее количество товаров',
                  id: 'receiptConfirmation.input-label.totalAmountOfGoods',
                  description: 'Заголовок поля ввода',
                })}
              >
                <div>
                  {order.count}
                  <FormattedMessage
                    defaultMessage="шт"
                    id="input.label.pcs"
                    description="Единица измерения"
                  />
                </div>
              </InputWrapper>
            </div>
          )}
        </div>
      </div>

      {order.storageLocation && (
        <div className="mt-3">
          <div className="row">
            <div className="col">
              <InputWrapper
                theme="light"
                title={intl.formatMessage({
                  defaultMessage: 'Место хранения',
                  id: 'storage.label.storagePlace',
                  description: 'Информация о месте хранения',
                })}
              >
                <div>{order.storageLocation}</div>
              </InputWrapper>
            </div>
          </div>
        </div>
      )}
      {order.receiptComment && (
        <div className="mt-3">
          <div className="row">
            <div className="col">
              <InputWrapper
                theme="light"
                title={intl.formatMessage({
                  defaultMessage: 'Комментарий',
                  id: 'receiptConfirmation.input-label.receiptComment',
                  description: 'Комментарий',
                })}
              >
                <div>{order.receiptComment}</div>
              </InputWrapper>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
