import React, { Dispatch, SetStateAction, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import { fetcher } from '../../services/api';

export type User = {
  id: string;
  internalId: number | null;
  phone: string;
  name: string;
};

type UserSelectProps = {
  onChange?: (data: User | null) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  name: string;
  placeholder?: string;
  value: User | null;
  roles?: string[];
  setUserId?: (userId: number | null) => void | undefined;
};

export function getLoader(roles: string[]) {
  return async (inputValue: string) =>
    fetcher({
      url: '/user',
      params: {
        take: 15,
        roles,
        query: inputValue || undefined,
      },
    });
}

export const UserSelect = React.forwardRef(function (
  props: UserSelectProps,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  useEffect(() => {
    if (props.value && props.setUserId) {
      props.setUserId(props.value.internalId);
    }
  }, [props.value, props.setUserId]);

  return (
    <AsyncSelect
      name={props.name}
      placeholder={props.placeholder}
      onBlur={props.onBlur}
      loadOptions={getLoader(props.roles ?? ['client'])}
      cacheOptions
      defaultOptions
      getOptionLabel={(option) =>
        option.internalId
          ? `ID ${option.internalId} / ${option.phone} / ${option.name}`
          : `${option.phone} / ${option.name}`
      }
      getOptionValue={(option) => option.id}
      isMulti={false}
      onChange={props.onChange}
      value={props.value}
      //@ts-ignore
      ref={ref}
    />
  );
});
