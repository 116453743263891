import React, { HTMLAttributes } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { CleanPurchaseOrder } from '../../../export-types/cleaned-types';
import { ImagesCell } from '../../common/cards/images-cell-card.component';
import { IdCell } from '../../common/cards/id-cell-card.component';

type Props = {
  order: CleanPurchaseOrder;
  type?: 'default' | 'metadata'; // Отображать полную информацию о товаре (default), либо метаданные (metadata)
} & HTMLAttributes<HTMLDivElement>;

/** Элемент списка заказов в поиске ТСД */
export const TerminalPurchaseOrderItem = ({
  order,
  type = 'default',
  onClick,
  ...props
}: Props) => {
  const navigate = useNavigate();
  const intl = useIntl();

  // Открытть страницу заказа при клике
  function onOrderClick(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    onClick?.(e);
    navigate(`/terminal/purchases/${order.id}`);
  }

  return (
    <div
      key={order.id}
      role="button"
      tabIndex={0}
      {...props}
      onClick={onOrderClick}
      className={classNames(
        'd-flex flex-row align-items-center p-2 rounded',
        order.status === 'onTheWayToStock'
          ? 'bg-success bg-opacity-25'
          : 'bg-light cursor-auto',
        props.className,
      )}
    >
      {type === 'default' && (
        <ImagesCell width={68} height={60} imagesList={order.requestFilesV2} />
      )}
      <div className="me-auto w-100">
        {/* Заголовок заказа, его ID */}
        <div className="d-flex justify-content-between">
          <div style={{ fontSize: '1.25rem' }}>
            <IdCell internalId={order.internalId} type={order.type} />
          </div>
          {type === 'metadata' && (
            <div className="d-flex gap-1" style={{ fontSize: '0.75rem' }}>
              <i className="bi bi-circle text-success" />
              {(() => {
                switch (order.status) {
                  case 'receivedInRetailStock':
                    return intl.formatMessage({
                      defaultMessage: 'На складе (Розница)',
                      id: 'terminal.value.inRetailStorage',
                      description: 'Значение Пункта',
                    });
                  case 'receivedInStock':
                    return intl.formatMessage({
                      defaultMessage: 'На складе',
                      id: 'terminal.value.inStorage',
                      description: 'Значение Пункта',
                    });
                  default:
                    return intl.formatMessage({
                      defaultMessage: 'В пути',
                      id: 'terminal.value.onWay',
                      description: 'Значение Пункта',
                    });
                }
              })()}
            </div>
          )}
        </div>

        {/* Контейнер с доп.ифнормацией о заказе */}
        <div
          className="d-flex flex-row gap-2"
          style={{ fontSize: type === 'default' ? '0.75rem' : '0.85rem' }}
        >
          {type === 'default' ? (
            <>
              {/* Код владельца заказа */}
              {order.user && (
                <div className="d-flex gap-1 align-items-center">
                  <i className="bi bi-person-fill text-success" />
                  {order.user.internalId}
                </div>
              )}

              {/* Статус заказа */}
              <div className="d-flex gap-1 align-items-center">
                <i className="bi bi-circle text-success" />
                {order.status === 'onTheWayToStock'
                  ? intl.formatMessage({
                      defaultMessage: 'В пути',
                      id: 'terminal.value.onWay',
                      description: 'Значение Пункта',
                    })
                  : intl.formatMessage({
                      defaultMessage: 'На складе',
                      id: 'terminal.value.inStorage',
                      description: 'Значение Пункта',
                    })}
              </div>

              {/* Названия транспортной компании, если заказ в пути */}
              {order.transportCompany && (
                <div className="d-flex gap-1 align-items-center">
                  <i className="bi bi-truck text-success" />
                  {order.transportCompany}
                </div>
              )}
            </>
          ) : (
            <>
              {/* Вес товара */}
              {order.weight && (
                <div className="d-flex gap-1 align-items-center">
                  <FormattedMessage
                    defaultMessage="Вес"
                    id="terminal.item.weight"
                    description="Значение в элементе списка"
                  />
                  :
                  <span className="fw-bold">
                    {order.weight}{' '}
                    <FormattedMessage
                      defaultMessage="кг"
                      id="input.label.kg"
                      description="Единица измерения"
                    />
                  </span>
                </div>
              )}

              {/* Объём товара */}
              {order.volume && (
                <div className="d-flex gap-1 align-items-center">
                  <FormattedMessage
                    defaultMessage="Объём"
                    id="terminal.item.volume"
                    description="Значение в элементе списка"
                  />
                  :
                  <span className="fw-bold">
                    {order.volume}{' '}
                    <FormattedMessage
                      defaultMessage="м³"
                      id="input.label.length"
                      description="Единица измерения"
                    />
                  </span>
                </div>
              )}

              {/* Место на складе */}
              {order.storageLocation && (
                <div className="d-flex gap-1 align-items-center">
                  <FormattedMessage
                    defaultMessage="Место"
                    id="terminal.item.place"
                    description="Значение в элементе списка"
                  />
                  :<span className="fw-bold">{order.storageLocation}</span>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
