import React from 'react';
import useSWR from 'swr';
import { fetcher } from '../../../services/api';
import { NavLink } from 'react-router-dom';
import { IdCell } from '../../common/cards/id-cell-card.component';
import {
  CleanCompositeDelivery,
  CleanDeliveryOrder,
} from '../../../export-types/cleaned-types';
import { FormattedMessage } from 'react-intl';
import { ModalsService } from '../../../services/modals.service';
import { DeliveryOrderAssemblyModal } from '../delivery/delivery-order-assembly-modal';

type CompositeDeliveryOrdersInfoProps = {
  id: string;
};
export const CompositeDeliveryOrdersInfo: React.FC<
  CompositeDeliveryOrdersInfoProps
> = (props) => {
  const { data: order, mutate } = useSWR<CleanCompositeDelivery>(
    `/composite-delivery/${props.id}`,
    fetcher,
  );
  if (!order) {
    return null;
  }

  function assemblyDelivery(order: CleanDeliveryOrder) {
    return ModalsService.createModal(DeliveryOrderAssemblyModal, {
      deliveryOrderId: order.id,
      composite: true,
    }).then(() => mutate());
  }
  return (
    <div>
      <div className="d-flex flex-row justify-content-between mb-2">
        <div className="fs-18 fw-600 color-gray-450">Посылки</div>
      </div>
      <table className="table table-responsive table-borderless rounded  mt-3 bg-white">
        <thead className="color-gray-600 border-bottom">
          <tr>
            <td>
              <FormattedMessage
                defaultMessage="Посылка"
                id="ldcCsn"
                description="Доставка в форме посылка"
              />
            </td>
            <td>
              <FormattedMessage
                defaultMessage="Клиент"
                id="Db9+iD"
                description="Доставка в форме Посылка"
              />
            </td>
            <td className="text-end"></td>
          </tr>
        </thead>
        <tbody>
          {order.deliveryOrders?.map((deliveryOrder) => (
            <tr>
              <td>
                <NavLink
                  className="btn-link cursor-pointer text-decoration-underline"
                  to={`/deliveries/delivery-order/${deliveryOrder.id}`}
                >
                  <IdCell
                    internalId={deliveryOrder.internalId}
                    type="delivery"
                  />
                </NavLink>{' '}
              </td>
              <td>{deliveryOrder.user?.internalId}</td>
              <td className="text-end">
                {deliveryOrder.status === 'assembly' && (
                  <div
                    className="btn btn-success"
                    onClick={() => assemblyDelivery(deliveryOrder)}
                  >
                    Укомплектовать
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
