import React from 'react';
import { StatusBlock } from '../../common/status-block';
import Whisper from 'rsuite/Whisper';
import Tooltip from 'rsuite/Tooltip';
import moment from 'moment';
import { CleanDeliveryOrder } from '../../../export-types/cleaned-types';
import { FormattedMessage, useIntl } from 'react-intl';

type AdminDeliveryStatusTableProps = {
  order: CleanDeliveryOrder;
};

export const AdminDeliveryStatusTable: React.FC<
  AdminDeliveryStatusTableProps
> = ({ order }) => {
  const deliveryDate = React.useMemo(() => {
    if (!order) return undefined;

    if (order.status !== 'sentToRussia' && order.receiveRfDate) {
      const date = moment(order.receiveRfDate).format('DD.MM.YYYY, HH:mm');
      if (order.deliveryMethod?.destination) {
        return `${
          order.deliveryMethod?.destination?.displayName ?? ''
        }, ${date}`;
      } else {
        return date;
      }
    }

    if (
      order.status === 'sentToRussia' &&
      !order.receiveRfDate &&
      order.deliveryMethod
    ) {
      const start = moment(order.sentDate)
        .add(order.deliveryMethod.deliveryPeriodFrom, 'd')
        .format('DD.MM.YYYY');
      const end = moment(order.sentDate)
        .add(order.deliveryMethod.deliveryPeriodTo, 'd')
        .format('DD.MM.YYYY');

      return `Получим ${start} - ${end}`;
    }

    return undefined;
  }, [order]);

  const intl = useIntl();

  const deliveryPeriodTooltip = (
    <Tooltip className="fs-14 text-start lh-sm p-2" style={{ maxWidth: 293 }}>
      Указана ориентировочная дата получения. В случае осуществления таможенной
      проверки груза, срок доставки может быть увеличен до 92 дней.
    </Tooltip>
  );

  return (
    <ul className="d-flex flex-column p-0 mb-0">
      <StatusBlock
        title={
          order.status === 'assembly' ? (
            <FormattedMessage
              defaultMessage="На комплектации"
              id="delivery.status-label.onPicking"
              description="Статус доставки"
            />
          ) : order.status === 'assembled' ? (
            <FormattedMessage
              defaultMessage="Упакован"
              id="RLuCwI"
              description="Статус доставки"
            />
          ) : order.status === 'processing' ? (
            <FormattedMessage
              defaultMessage="Заморожен"
              id="delivery.status-label.processing"
              description="Статус доставки"
            />
          ) : (
            <FormattedMessage
              defaultMessage="Упакован и отправлен"
              id="delivery.status-label.packedAndShipped"
              description="Статус доставки"
            />
          )
        }
        subtitle={
          order.sentDate &&
          (order.supplier?.location
            ? `${order.supplier.location.displayName}, ${moment(
                order.sentDate,
              ).format('DD.MM.YYYY, HH:mm')}`
            : moment(order.sentDate).format('DD.MM.YYYY, HH:mm'))
        }
        status={
          order.status === 'assembly' || order.status === 'processing'
            ? 'active'
            : order.sentDate
              ? 'done'
              : undefined
        }
        connector={true}
        key={'assembly'}
      />

      <StatusBlock
        title={
          order.status === 'sentToRussia' ? (
            <>
              <FormattedMessage
                defaultMessage="В пути на склад в России"
                id="delivery.status-label.InTransitToAWarehouseInRussia"
                description="Статус доставки"
              />
              <Whisper
                placement="right"
                trigger="hover"
                speaker={deliveryPeriodTooltip}
              >
                <i className="ms-2 bi bi-question-circle" />
              </Whisper>
            </>
          ) : (
            <FormattedMessage
              defaultMessage="Прибыл на склад в России"
              id="delivery.status-label.arrivedAtWarehouseInRussia"
              description="Статус доставки"
            />
          )
        }
        subtitle={deliveryDate}
        status={
          order.status === 'sentToRussia'
            ? 'active'
            : order.receiveRfDate
              ? 'done'
              : undefined
        }
        connector={true}
        key={'sentToRussia'}
      />

      <StatusBlock
        title={
          ['receivedInRussia', 'receivedInRetailRussia'].includes(order.status)
            ? intl.formatMessage(
                {
                  defaultMessage: 'Ожидает передачи в ТК {value}',
                  id: 'delivery.status-label.awaitingToTransferToTheShippingCompany',
                  description: 'Статус доставки',
                },
                { value: order.localDeliveryMethod.name },
              )
            : !order.blocked
              ? intl.formatMessage(
                  {
                    defaultMessage: 'Передан в ТК {value}',
                    id: 'delivery.status-label.transferredToTheShippingCompany',
                    description: 'Статус доставки',
                  },
                  { value: order.localDeliveryMethod.name },
                )
              : intl.formatMessage(
                  {
                    defaultMessage: 'Заблокирован в ТК {value}',
                    id: 'delivery.status-label.blockedAtTheShippingCompany',
                    description: 'Статус доставки',
                  },
                  { value: order.localDeliveryMethod.name },
                )
        }
        subtitle={
          order.sentToRecipientDate ? (
            <>
              <div>
                {`${order.localDeliveryMethod.location?.displayName}, ${moment(
                  order.sentToRecipientDate,
                ).format('DD.MM.YYYY, HH:mm')}`}
              </div>
              {order.trackNumber && (
                <div className="fs-14 fw-400 text-dark mt-2">
                  Трек-номер:
                  <span className="p-1 ms-1 border rounded-8">
                    {order.trackNumber}
                  </span>
                </div>
              )}
            </>
          ) : (
            <div>{`${order.localDeliveryMethod.location?.displayName}`}</div>
          )
        }
        status={
          order.blocked
            ? 'canceled'
            : ['receivedInRussia', 'receivedInRetailRussia'].includes(
                  order.status,
                )
              ? 'active'
              : order.sentToRecipientDate
                ? 'done'
                : undefined
        }
        connector={true}
        key={'receivedInRussia'}
      />

      <StatusBlock
        title={
          order.status === 'sentToRecipient' ? (
            <FormattedMessage
              defaultMessage="Ожидает вручения"
              id="delivery.status-label.awaitingReceipt"
              description="Статус доставки"
            />
          ) : (
            <FormattedMessage
              defaultMessage="Получен клиентом"
              id="delivery.status-label.receiptedByTheClient"
              description="Статус доставки"
            />
          )
        }
        subtitle={
          order.deliveredDate &&
          moment(order.deliveredDate).format('DD.MM.YYYY, HH:mm')
        }
        status={
          order.status === 'sentToRecipient'
            ? 'active'
            : order.deliveredDate
              ? 'done'
              : undefined
        }
        connector={false}
        key={'sentToRecipient'}
      />
    </ul>
  );
};
