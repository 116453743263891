import isEmail from 'validator/es/lib/isEmail';
import isDecimal from 'validator/es/lib/isDecimal';
import isURL from 'validator/es/lib/isURL';
import isInt from 'validator/es/lib/isInt';
import isAlphanumeric from 'validator/es/lib/isAlphanumeric';
import { RegisterOptions } from 'react-hook-form';
import { PromocodePattern } from '../export-types/cleaned-types';
import { DECIMAL_PLACES } from './common.utils';

type Validator = Omit<
  RegisterOptions,
  'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
>;

export const required: Validator = {
  required: 'Заполните поле',
};
export const phoneValidation: Validator = {
  validate: {
    correctPhone: (v) => {
      if (import.meta.env.DEV) {
        return true;
      }
      return v[4] === '9' || 'Код оператора должен начинаться с цифры 8 или 9';
    },
    length: (v) =>
      v.match(/\d+/g).join('').length === 11 ||
      'Номер телефона должен состоять из 10 цифр, начиная с кода оператора',
  },
};

export const emailValidation: Validator = {
  validate: {
    correctEmail: (v) =>
      (typeof v === 'string' && v.length === 0) ||
      isEmail(`${v}`) ||
      'Введите корректный e-mail',
  },
};

export const cardValidation: Validator = {
  validate: {
    correctCard: (v) =>
      (typeof v === 'string' && v.length === 0) ||
      true ||
      'Введите корректный номер карты',
  },
};

export const decimalValidation: Validator = {
  validate: {
    correctDecimal: (v) =>
      (typeof v === 'string' && v.length === 0) ||
      isDecimal(`${v}`, { decimal_digits: '0,2' }) ||
      'Введите число',
  },
};
export const decimalValidationCurrency: Validator = {
  validate: {
    correctDecimal: (v) =>
      (typeof v === 'string' && v.length === 0) ||
      isDecimal(`${v}`, { decimal_digits: `0,${DECIMAL_PLACES}` }) ||
      'Введите число',
  },
};

export const intValidation: Validator = {
  validate: {
    correctInt: (v) =>
      (typeof v === 'string' && v.length === 0) ||
      isInt(`${v}`) ||
      'Введите целое число',
  },
};

export const urlValidation: Validator = {
  validate: {
    correctUrl: (v) =>
      (typeof v === 'string' && v.length === 0) ||
      isURL(`${v}`, { require_protocol: true }) ||
      'Введите ссылку',
  },
};

export const alphanumericValidation: Validator = {
  validate: {
    correctAlphanumeric: (v) =>
      (typeof v === 'string' && v.length === 0) ||
      isAlphanumeric(`${v}`) ||
      'Введите строку состоящую только из чисел и латинских букв',
  },
};

export const promocodeValidation: Validator = {
  validate: {
    withoutWhitespace: (v) =>
      PromocodePattern.test(v) || 'Строка не должна содержать пробелов',
  },
};

export const decimalValidationAdditionalServiseSum: Validator = {
  validate: {
    correctDecimal: (v) =>
      (typeof v === 'string' && v.length === 0) ||
      (v !== '0' &&
        isDecimal(`${v}`, { decimal_digits: `0,${DECIMAL_PLACES}` })) ||
      'Введите число больше 0',
  },
};

export const nonNegativeNumberValidation: Validator = {
  validate: {
    nonNegativeDecimal: (v) =>
      (typeof v === 'string' && parseFloat(v as string) >= 0) ||
      'Введите число больше или равное нулю',
  },
};
