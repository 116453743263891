import { api } from './api';
import { state } from '../state';
import { ProfileService } from './profile.service';
const userCursor = state.select('user');

const TOKEN = 'JWT:TOKEN';

type LoginData = {
  password: string;
  username: string;
};

export class AuthService {
  static async login(data: LoginData): Promise<void> {
    await api.post('/login', data);
  }

  static async impersonate(user: string) {
    return await api.post(`/impersonate/${user}`);
  }

  static async logout(): Promise<void> {
    await api.post('/logout').then(async () => {
      AuthService.removeToken();
      try {
        await ProfileService.getCurrentUser();
      } catch (e) {
        userCursor.unset();
      }
    });
  }

  static async checkCode(id: string, code: string): Promise<void> {
    await api.post('/check-code', { code, id });
  }

  static async setPassword(
    id: string,
    code: string,
    password: string,
  ): Promise<{ token: string }> {
    return api
      .post('/set-password', { id, code, password })
      .then(({ data }) => data);
  }

  static async forgotPassword(phone: string): Promise<{ id: string }> {
    return api.post('/forgot-password', { phone }).then(({ data }) => data);
  }

  static getToken(): string | null {
    return localStorage.getItem(TOKEN);
  }

  static removeToken(): void {
    localStorage.removeItem(TOKEN);
  }
}
