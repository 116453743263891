import React from 'react';
import { InputWrapper } from '../../common/input-wrapper';
import useSWR from 'swr';
import { fetcher } from '../../../services/api';
import { getDeliveryPeriod, toMoney } from '../../../utils/common.utils';
import { CleanCompositeDelivery } from '../../../export-types/cleaned-types';
import { useProfile } from '../../../api/profile';
import { FormattedMessage, useIntl } from 'react-intl';

type CompositeDeliveryInfoProps = {
  id: string;
};
export const CompositeDeliveryInfo: React.FC<CompositeDeliveryInfoProps> = (
  props,
) => {
  const { data: user } = useProfile();
  const { data: order } = useSWR<CleanCompositeDelivery>(
    `/composite-delivery/${props.id}`,
    fetcher,
  );
  const intl = useIntl();
  if (!order || !user) {
    return null;
  }

  return (
    <div>
      <div className="d-flex flex-row justify-content-between">
        <div className="fs-18 fw-600 color-gray-450">
          <FormattedMessage
            defaultMessage="О транзите"
            id="yIVAZf"
            description="Заголовок на странице"
          />
        </div>
      </div>
      <InputWrapper
        theme="light"
        className="mt-3"
        title={intl.formatMessage({
          defaultMessage: 'Общая стоимость товаров в заказе',
          id: 'W5mOuO',
          description: 'Заголовок поля ввода',
        })}
      >
        {toMoney(order.moderateProductPrice!, 'cny')}
      </InputWrapper>
      <InputWrapper
        theme="light"
        className="mt-3"
        title={intl.formatMessage({
          defaultMessage: 'Доставка Китай — РФ',
          id: 'dKtndF',
          description: 'Заголовок поля ввода',
        })}
      >
        {order.deliveryOrders && order.deliveryOrders[0].deliveryMethod ? (
          <>
            {order.deliveryOrders[0].deliveryMethod.name},{' '}
            {getDeliveryPeriod(order.deliveryOrders[0].deliveryMethod)}
          </>
        ) : null}
      </InputWrapper>

      <InputWrapper
        theme="light"
        className="mt-3"
        title={intl.formatMessage({
          defaultMessage: 'Адрес доставки и получатель',
          id: 'S66TKY',
          description: 'Заголовок поля ввода',
        })}
      >
        <div>Транизитный склад РФ</div>
      </InputWrapper>
    </div>
  );
};
