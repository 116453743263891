import { api } from './api';

export class DeliveryOrderService {
  static updateDeliveryOrder(data: unknown, id: string) {
    return api.put(`/delivery-order/${id}`, data);
  }

  static deleteDeliveryOrder(id: string) {
    return api.delete(`/delivery-order/${id}`);
  }
}
