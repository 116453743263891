import React from 'react';
import { Outlet } from 'react-router-dom';
import { DeliveryOrdersTabs } from './delivery-orders-tabs';
import { useDebouncedState, useLocalStorage } from '@mantine/hooks';
import Toggle from 'rsuite/Toggle';
import { FormattedMessage, useIntl } from 'react-intl';
import { RbacObject } from '../../../export-types/cleaned-types';
import { Rbac } from '../../common/Rbac';
import {
  SupplierSelect,
  useSelectSupplierQs,
} from '../purchase-order/supplier-select';

export type OutletContextParams = {
  debouncedInternalId: string;
  linked: boolean;
  expanded: boolean;
  collapsed: string[];
  onChangeCollapsed: (hidden: string[]) => void;
  selectSupplier: number | undefined;
};

export const AdminDeliveryOrdersScreen = () => {
  const [internalId, setInternalId] = useDebouncedState('', 200);
  const [linked, setLinked] = React.useState(false);
  const [expanded, setExpanded] = useLocalStorage({
    key: 'delivery-order-expand',
    defaultValue: true,
  });
  const [collapsed, setCollapsed] = React.useState<string[]>([]);
  const [selectSupplier] = useSelectSupplierQs();

  const intl = useIntl();

  const context: OutletContextParams = {
    debouncedInternalId: internalId,
    linked,
    expanded: expanded ?? true,
    collapsed,
    onChangeCollapsed: setCollapsed,
    selectSupplier: selectSupplier,
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center pb-4 ">
        <div className="d-flex w-75 mr-auto">
          <input
            className="form-control w-25 mb-4"
            type="text"
            placeholder={intl.formatMessage({
              defaultMessage: 'Поиск по номеру заказа/клиента',
              id: 'input.placeholder.searchByOrder/CustomerNumber',
              description: 'Поле ввода',
            })}
            onChange={(event) => setInternalId(event.currentTarget.value)}
          />
          <Rbac object={RbacObject.Supplier} action={'read:admin'}>
            <SupplierSelect />
          </Rbac>
        </div>
        <div className="d-flex">
          <Toggle checked={linked} onChange={() => setLinked(!linked)} />
          <div className="ms-2">
            <FormattedMessage
              defaultMessage="Мои клиенты"
              id="switchButton.label.myCustomers"
              description="Надпись на переключателе"
            />
          </div>
        </div>
        <div className="d-flex align-items-center text-center">
          <button
            className="btn mr-4"
            onClick={() => {
              setExpanded(!expanded);
              setCollapsed([]);
            }}
          >
            {expanded ? (
              <i className="bi bi-chevron-down"></i>
            ) : (
              <i className="bi bi-chevron-up"></i>
            )}
          </button>
          <div className="ms-2">
            <FormattedMessage
              defaultMessage="Показать/скрыть заказы"
              id="mo2h2B"
              description="Надпись на переключателе"
            />
          </div>
        </div>
      </div>
      <div className="mb-4">
        <DeliveryOrdersTabs
          selectSupplier={selectSupplier}
          debouncedInternalId={internalId}
          linked={linked}
          expanded={expanded ?? true}
          collapsed={collapsed}
          onChangeCollapsed={setCollapsed}
        />
      </div>
      <Outlet context={context} />
    </>
  );
};
