import useSWR from 'swr';
import { api, fetcher } from './api';
import { CleanTransaction } from '../export-types/cleaned-types';
import moment from 'moment';
import { parseRawDateValue } from '../utils/date-value';

type CreateCompanyTransaction = {
  currency: string;
  amount: number;
  privateComment: string;
  type: 'writeOff' | 'refill';
  supplierId?: number;
  currencyRate?: number;
};

export class TransactionService {
  static createCompanyTransaction(transaction: CreateCompanyTransaction) {
    return api.post('/transaction/company', transaction);
  }

  static checkTransaction(transactionUuid: string) {
    return api
      .post(`/transaction/check/${transactionUuid}`)
      .then(({ data }) => {
        return data;
      });
  }
  static cancelTransaction(transactionUuid: string) {
    return api.post(`/transaction/cancel/${transactionUuid}`);
  }
}

export type Direction = 'desc' | 'asc';
export type TransactionType = 'refill' | 'writeOff';
export type TransactionStatus =
  | 'draft'
  | 'processing'
  | 'confirmed'
  | 'canceled';

export type UseTransactionsListProps = Partial<{
  direction: Direction; // В каком порядке сортировать, например в обратном
  take: number;
  skip: number;
  sort: string;
  start: string; // Взять транзакции в диапозоне ОТ
  end: string; // И взять в диапозоне ДО
  userInternalId: string;
  accountCurrency: string;
  type: TransactionType; // Списание или пополнение
  status: TransactionStatus[]; // Статус транзакции, отменена, в процессе и др.
}>;

/** Хук для получения списка транзакций с сервера */
export const useTransactionsList = ({
  start,
  end,
  ...params
}: UseTransactionsListProps = {}) => {
  return useSWR<{ total: number; items: Array<CleanTransaction> }>(
    {
      url: `/transaction/v2`,
      params: {
        type: 'refill',
        sort: 'createdAt',
        status: ['draft', 'confirmed', 'processing'],
        start: start
          ? parseRawDateValue(
              moment(start, 'DD.MM.YYYY').format('DD-MM-YYYY'),
            ).valueOf()
          : undefined,
        end: end
          ? parseRawDateValue(
              moment(end, 'DD.MM.YYYY').add(1, 'day').format('DD-MM-YYYY'),
            ).valueOf()
          : undefined,
        ...params,
      },
    },
    fetcher,
  );
};
