import { RenderProps } from './employee-purchase-orders-tab';
import classNames from 'classnames';
import tableStyle from './purchase-order-item.module.css';
import moment from 'moment';
import { IdCell } from '../../common/cards/id-cell-card.component';
import { UserInternalIdCell } from '../../common/cards/id-user-cell-card.component';
import { ImagesCell } from '../../common/cards/images-cell-card.component';
import { NameCell } from '../../common/cards/name-cell-card.component';
import { PriceCell } from '../../common/cards/price-cell-card.component';
import { StatusCell } from '../../common/cards/status-cell-card.component';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Rbac, useRbac } from '../../common/Rbac';
import { RbacObject } from '../../../export-types/cleaned-types';
import { Checkbox } from '../../common/checkbox';
import { BlockStatus } from '../../common/block-status.component';
import { OrderCell } from '../../common/cards/order-cell-card.component';
import { SelectedOrdersContext } from './admin-purchases-screen.component';
import { Link } from 'react-router-dom';

function ErrorIcon() {
  return (
    <i
      className="bi bi-exclamation-circle-fill ms-2"
      style={{ color: '#F23E2C', fontSize: '16px' }}
    />
  );
}

function WriteOffIcon() {
  return (
    <i
      className="bi bi-cash-coin mx-2"
      style={{ color: '#12B76A', fontSize: '16px' }}
    />
  );
}

export function AdminPurchaseOrderItem({ ...props }: RenderProps) {
  const { isLoading, hasPermission } = useRbac();

  const { selectedOrders, setSelectedOrders, isSelectAll, isUnselectAll } =
    useContext(SelectedOrdersContext);

  const [orders] = useState(selectedOrders);
  const [id] = useState(props.data.id);

  const canReadExtendedData =
    !isLoading && hasPermission(RbacObject.Purchase, 'read:extended-statuses');

  const [isSelected, setIsSelected] = useState(false);

  const onChangeSelectCheckbox = () => {
    const newSelectedOrders = new Set(selectedOrders);
    if (!isSelected) {
      newSelectedOrders.add(props.data.id);
      setSelectedOrders(newSelectedOrders);
    } else {
      newSelectedOrders.delete(props.data.id);
      setSelectedOrders(newSelectedOrders);
    }
    setIsSelected((prevState) => !prevState);
  };

  const setSelectedState = useCallback(
    (isSelected: boolean) => {
      const newSelectedOrders = new Set(orders);
      if (isSelected) {
        newSelectedOrders.add(id);
      } else {
        newSelectedOrders.delete(id);
      }
      setIsSelected(isSelected);
    },
    [orders, id],
  );

  useEffect(() => {
    setSelectedState(true);
  }, [setSelectedState, isSelectAll]);

  useEffect(() => {
    setSelectedState(false);
  }, [setSelectedState, isUnselectAll]);

  return (
    <div
      className={classNames(
        'row cursor-pointer g-0 position-relative',
        tableStyle.tableRow,
        {
          [tableStyle.tableRowError]:
            Number(props.data.productPrice) <
              Number(props.data.moderateProductPrice) ||
            Number(props.data.deliveryPrice) <
              Number(props.data.moderateDeliveryPrice) ||
            (props.type === 'onTheWayToStock' &&
              moment().diff(moment(props.data.purchaseDate), 'days') > 10) ||
            (props.type === 'receivedInStock' &&
              moment().diff(moment(props.data.receiptDate), 'days') > 15),
        },
      )}
      onClick={(e) => {
        props.onClick(props.data);
        e.stopPropagation();
      }}
      key={props.data.id}
    >
      {(props.data?.unreadMessages ?? 0) > 0 && (
        <div className="position-absolute top-0 start-0">
          <span className="badge bg-green-500">
            {props.data.unreadMessages}
          </span>
        </div>
      )}
      {(props.type === 'waitingForPayment' || props.type === 'inWork') && (
        <>
          <Rbac object={RbacObject.Purchase} action="read:extended-statuses">
            <div className="col-sm-2 col-xl-1 col">
              {props.data.supplier?.name ?? ''}
            </div>
          </Rbac>
          <div className="col">
            <Link
              to={`/purchases/${props.data.id}`}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <IdCell
                internalId={props.data.internalId}
                type={props.data.type}
              />
            </Link>
          </div>
          {props.type === 'inWork' && (
            <div
              className="col h-100 pt-2"
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <input
                className="form-check-input"
                type="checkbox"
                checked={isSelected}
                onChange={() => onChangeSelectCheckbox()}
              />
            </div>
          )}
          <div className="col">
            <UserInternalIdCell user={props.data.user} />
          </div>
          <div className="col">
            <ImagesCell imagesList={props.data.requestFilesV2} />
          </div>
          <div className={canReadExtendedData ? 'col-sm-1 col-xl-4' : 'col-6'}>
            <NameCell name={props.data.name} category={props.data.category} />
          </div>
          <div className="col-2">
            <div className="color-gray-800 fs-16">
              {props.type === 'waitingForPayment'
                ? moment(props.data.createdAt).format('DD.MM.YYYY')
                : moment(props.data.paidAt).format('DD.MM.YYYY')}
            </div>
            <div className="color-gray-600 fs-14">
              {props.type === 'waitingForPayment' ? (
                <FormattedMessage
                  defaultMessage="Создан"
                  id="order.status-label.created"
                  description="Статус заказа"
                />
              ) : (
                <FormattedMessage
                  defaultMessage="Оплачен"
                  id="order.status-label.paidOff"
                  description="Статус заказа"
                />
              )}
            </div>
          </div>
          <Rbac object={RbacObject.Purchase} action="read:extended-statuses">
            <div className="col-2 text-truncate">
              {props.data.user?.manager?.name ?? ''}
            </div>
          </Rbac>
          <Rbac object={RbacObject.Purchase} action="read:extended-statuses">
            <div className="col text-end">
              <PriceCell
                price={props.data.price!}
                currency={props.data.currency ?? 'cny'}
              />
            </div>
          </Rbac>
        </>
      )}
      {props.type === 'frozen' && (
        <>
          <Rbac object={RbacObject.Purchase} action="read:extended-statuses">
            <div className="col-sm-2 col-xl-1 col">
              {props.data.supplier?.name ?? ''}
            </div>
          </Rbac>
          <div
            className="col h-100 pt-2"
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <input
              className="form-check-input"
              type="checkbox"
              checked={isSelected}
              onClick={(event) => {
                event.stopPropagation();
              }}
              onChange={() => onChangeSelectCheckbox()}
            />
          </div>
          <div className="col">
            <Link
              to={`/purchases/${props.data.id}`}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <IdCell
                internalId={props.data.internalId}
                type={props.data.type}
              />
            </Link>
          </div>
          <div className="col">
            <UserInternalIdCell user={props.data.user} />
          </div>
          <div className="col">
            <ImagesCell imagesList={props.data.requestFilesV2} />
          </div>
          <div className={canReadExtendedData ? 'col-sm-1 col-xl-2' : 'col-4'}>
            <NameCell name={props.data.name} category={props.data.category} />
          </div>
          <div className="col-2 text-truncate">
            <div className="color-gray-800 fs-16">
              {props.data.freezingReason}
            </div>
            <div className="color-gray-600 fs-14">Причина заморозки</div>
          </div>
          <div className="col-2">
            <div className="color-gray-800 fs-16">
              {moment(props.data.paidAt).format('DD.MM.YYYY')}
            </div>
            <div className="color-gray-600 fs-14">
              <FormattedMessage
                defaultMessage="Оплачен"
                id="order.status-label.paidOff"
                description="Статус заказа"
              />
            </div>
          </div>
          <Rbac object={RbacObject.Purchase} action="read:extended-statuses">
            <div className="col-2 text-truncate">
              {props.data.user?.manager?.name ?? ''}
            </div>
          </Rbac>
          <Rbac object={RbacObject.Purchase} action="read:extended-statuses">
            <div className="col text-end">
              <PriceCell
                price={props.data.price!}
                currency={props.data.currency ?? 'cny'}
              />
            </div>
          </Rbac>
        </>
      )}
      {props.type === 'onTheWayToStock' && (
        <>
          <Rbac object={RbacObject.Purchase} action="read:extended-statuses">
            <div className="col-sm-2 col-xl-1 col">
              {props.data.supplier?.name ?? ''}
            </div>
          </Rbac>
          <div className="col">
            <Link
              to={`/purchases/${props.data.id}`}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <IdCell
                internalId={props.data.internalId}
                type={props.data.type}
              />
            </Link>
          </div>
          <div className="col">
            <UserInternalIdCell user={props.data.user} />
          </div>
          <div className="col">
            <ImagesCell imagesList={props.data.requestFilesV2} />
          </div>
          <div className={canReadExtendedData ? 'col-sm-1 col-xl-2 ' : 'col-3'}>
            <NameCell name={props.data.name} category={props.data.category} />
          </div>
          <div className={canReadExtendedData ? 'col-2 ps-3' : 'col-2'}>
            <div className="color-gray-800 fs-16">
              {moment(props.data.purchaseDate).format('DD.MM.YYYY')}
            </div>
            <div className="color-gray-600 fs-14">
              <FormattedMessage
                defaultMessage="Выкуплено"
                id="order.status-label.redeemed"
                description="Статус во вкладке"
              />
            </div>
          </div>
          <Rbac object={RbacObject.Purchase} action="read:extended-statuses">
            <div className="col-2 text-truncate">
              {props.data.user?.manager?.name ?? ''}
            </div>
          </Rbac>
          <div className="col-2">
            <OrderCell order={props.data} />
          </div>
          <Rbac object={RbacObject.Purchase} action="read:extended-statuses">
            <div className="col-1 text-end">
              <PriceCell
                price={props.data.price!}
                currency={props.data.currency ?? 'cny'}
              />
              {(Number(props.data.productPrice) <
                Number(props.data.moderateProductPrice) ||
                Number(props.data.deliveryPrice) <
                  Number(props.data.moderateDeliveryPrice)) && <ErrorIcon />}
            </div>
          </Rbac>
        </>
      )}
      {props.type === 'receivedInStock' && (
        <>
          <Rbac object={RbacObject.Purchase} action="read:extended-statuses">
            <div className="col-sm-2 col-xl-1 col">
              {props.data.supplier?.name ?? ''}
            </div>
          </Rbac>
          <div className="col-1">
            <Link
              to={`/purchases/${props.data.id}`}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <IdCell
                internalId={props.data.internalId}
                type={props.data.type}
              />
            </Link>
          </div>
          <div className="col-1">
            <UserInternalIdCell user={props.data.user} />
          </div>
          <div className="col-1">
            <ImagesCell imagesList={props.data.productFilesV2} />
          </div>
          <div className={canReadExtendedData ? 'col-sm-2 col-xl-3 ' : 'col-5'}>
            <NameCell name={props.data.name} category={props.data.category} />
          </div>
          <div className="ps-3 col-2">
            {props.data.storageLocation && (
              <>
                <div className="color-gray-800 fs-16">
                  {props.data.storageLocation}
                </div>
                <div className="color-gray-600 fs-14">
                  <FormattedMessage
                    defaultMessage="Место"
                    id="storage.label.place"
                    description="Место"
                  />
                </div>
              </>
            )}
          </div>
          <div className={canReadExtendedData ? 'ps-3 col' : 'col text-end'}>
            <div className="color-gray-800 fs-16">
              {moment(props.data.receiptDate).format('DD.MM.YYYY')}
            </div>
            <div className="color-gray-600 fs-14">
              <FormattedMessage
                defaultMessage="Получено"
                id="order.status-label.received"
                description="Статус во вкладке"
              />
            </div>
          </div>
          <Rbac object={RbacObject.Purchase} action="read:extended-statuses">
            <div className="col text-end">
              {props.data.additionalWriteOffs &&
                props.data.additionalWriteOffs?.length > 0 && <WriteOffIcon />}
              <PriceCell
                price={props.data.price!}
                currency={props.data.currency ?? 'cny'}
              />
              {(Number(props.data.productPrice) <
                Number(props.data.moderateProductPrice) ||
                Number(props.data.deliveryPrice) <
                  Number(props.data.moderateDeliveryPrice)) && <ErrorIcon />}
            </div>
          </Rbac>
        </>
      )}
      {props.type === 'receivedInRetailStock' && (
        <>
          <div
            className="col-1"
            onClick={(event: React.MouseEvent<HTMLElement>) =>
              event.stopPropagation()
            }
          >
            {!props.data.sendBlocked ? (
              <Checkbox
                checked={props.checked}
                onChange={(event) => {
                  props.onChecked(event.target.checked);
                  event.stopPropagation();
                }}
              />
            ) : (
              <BlockStatus blocked={true} className="ms-3" />
            )}
          </div>
          <div className="col-1">
            <Link
              to={`/purchases/${props.data.id}`}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <IdCell
                internalId={props.data.internalId}
                type={props.data.type}
              />
            </Link>
          </div>
          <div className="col-1">
            <UserInternalIdCell user={props.data.user} />
          </div>
          <div className="col-1">
            <ImagesCell imagesList={props.data.productFilesV2} />
          </div>
          <div className={canReadExtendedData ? 'col-3' : 'col-5'}>
            <NameCell name={props.data.name} category={props.data.category} />
          </div>
          <div className="ps-3 col-2">
            {props.data.storageLocation && (
              <>
                <div className="color-gray-800 fs-16">
                  {props.data.storageLocation}
                </div>
                <div className="color-gray-600 fs-14">Место / 储存地址</div>
              </>
            )}
          </div>
          <div
            className={canReadExtendedData ? 'ps-3 col-2' : 'col-2 text-end'}
          >
            <div className="color-gray-800 fs-16">
              {moment(props.data.receiptDate).format('DD.MM.YYYY')}
            </div>
            <div className="color-gray-600 fs-14">
              <FormattedMessage
                defaultMessage=" Получено"
                id="NiCw7p"
                description="Статус во вкладке"
              />
            </div>
          </div>
          <Rbac object={RbacObject.Purchase} action="read:extended-statuses">
            <div className="col-1 text-end">
              {props.data.additionalWriteOffs &&
                props.data.additionalWriteOffs?.length > 0 && <WriteOffIcon />}
              <PriceCell
                price={props.data.price!}
                currency={props.data.currency ?? 'cny'}
              />
              {(Number(props.data.productPrice) <
                Number(props.data.moderateProductPrice) ||
                Number(props.data.deliveryPrice) <
                  Number(props.data.moderateDeliveryPrice)) && <ErrorIcon />}
            </div>
          </Rbac>
        </>
      )}
      {props.type === 'archive' && (
        <>
          <Rbac object={RbacObject.Purchase} action="read:extended-statuses">
            <div className="col-sm-2 col-xl-1 col">
              {props.data.supplier?.name ?? ''}
            </div>
          </Rbac>
          <div className="col">
            <Link
              to={`/purchases/${props.data.id}`}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <IdCell
                internalId={props.data.internalId}
                type={props.data.type}
              />
            </Link>
            {Number(props.data.productPrice) <
              Number(props.data.moderateProductPrice) && <ErrorIcon />}
          </div>
          <div className="col">
            <UserInternalIdCell user={props.data.user} />
          </div>
          <div className={classNames('col', { 'pe-2': canReadExtendedData })}>
            <ImagesCell imagesList={props.data.productFilesV2} />
          </div>
          <div className={canReadExtendedData ? 'col-sm-2 col-xl-4' : 'col-5'}>
            <NameCell name={props.data.name} category={props.data.category} />
          </div>
          <Rbac object={RbacObject.Purchase} action="read:extended-statuses">
            <div className="col">
              {props.data.status !== 'canceled' && (
                <div>
                  <div className="color-gray-800 fs-16">
                    {moment(props.data.receiptDate).format('DD.MM.YYYY')}
                  </div>
                  <div className="color-gray-600 fs-14">
                    <FormattedMessage
                      defaultMessage=" Получено"
                      id="order.status-label.received"
                      description="Статус во вкладке"
                    />
                  </div>
                </div>
              )}
            </div>
          </Rbac>
          <div className="col-4 text-end" onClick={(e) => e.stopPropagation()}>
            {props.data.additionalWriteOffs &&
              props.data.additionalWriteOffs?.length > 0 && <WriteOffIcon />}
            <StatusCell
              deliveryOrder={props.data.deliveryOrder}
              status={props.data.status}
              cancelReason={props.data.cancelReason}
            />
          </div>
        </>
      )}
    </div>
  );
}
