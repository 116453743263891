import React from 'react';
import { Header } from '../../common/header.component';
import Dropdown from 'rsuite/Dropdown';
import { ModalsService } from '../../../services/modals.service';
import { CurrencyModal } from './currency-modal.component';
import { CurrencyConversionModal } from './currency-conversion-modal';
import { MoneyBillsComponent } from './money-bills.component';
import { RefillCompanyModal } from './refill-company-modal.component';
import { WriteOffCompanyModal } from './writeoff-company-modal.component';

import { TopUpModal } from './top-up-modal.component';
import { WithdrawClientModal } from './withdraw-client-modal';
import {
  RbacObject,
  RbacObjectActionsMap,
} from '../../../export-types/cleaned-types';
import { Enumerable, Rbac } from '../../common/Rbac';
import { useGlobalMutate } from '../../../api/useGlobalMutate';
import { NavLink, Outlet } from 'react-router-dom';
import { FinanceExportModal } from './finance-export-modal';
import { ReconciliationActModal } from './reconciliation-act-modal';
import { SupplierExpenseModal } from './supplier-expenses-modal';
import { WriteOffCompanyNewModal } from './writeoff-company-new-modal.component';
import { ProfitReportFromDiscountsModal } from './profit-report-from-discounts-modal';
import { PurchaseReportModal } from './purchase-report-modal';
import { ReportExportModal } from './report-export-modal';

export type RbacType<Object extends RbacObject> = {
  object: Object;
  action: Enumerable<(typeof RbacObjectActionsMap)[Object][number]>;
};

type Tab<Object extends RbacObject> = {
  path: string;
  title: string;
  rbac: RbacType<Object>;
};

const Refills: Tab<RbacObject.Transaction> = {
  path: 'refills',
  title: 'Пополнения',
  rbac: {
    object: RbacObject.Transaction,
    action: ['read:admin', 'read:supplier'],
  },
};
const Writeoffs: Tab<RbacObject.Transaction> = {
  path: 'write-offs',
  title: 'Списания',
  rbac: {
    object: RbacObject.Transaction,
    action: ['read:admin', 'read:supplier'],
  },
};
const Currency: Tab<RbacObject.Currency> = {
  path: 'currency',
  title: 'Курсы валют',
  rbac: { object: RbacObject.Currency, action: 'read' },
};
const TopUpMethods: Tab<RbacObject.TopUpMethod> = {
  path: 'top-up-accounts',
  title: 'Способы пополнения',
  rbac: { object: RbacObject.TopUpMethod, action: 'read:accounts' },
};

const tabs = [Refills, Writeoffs, Currency, TopUpMethods];

export const FinanceAdminScreen = () => {
  const { mutate } = useGlobalMutate();

  async function onSelectAdd(eventKey: number) {
    switch (eventKey) {
      case 1: {
        return ModalsService.createModal(TopUpModal, {}).then(mutate);
      }
      case 2: {
        return ModalsService.createModal(CurrencyModal, {});
      }
      case 3: {
        return ModalsService.createModal(CurrencyConversionModal, {});
      }
      case 4: {
        return ModalsService.createModal(RefillCompanyModal, {});
      }
      case 5: {
        return ModalsService.createModal(WriteOffCompanyModal, {});
      }
      case 6: {
        return ModalsService.createModal(WithdrawClientModal, {});
      }
      case 7: {
        return ModalsService.createModal(WriteOffCompanyNewModal, {});
      }
    }
  }

  async function handleFinanceExport() {
    return ModalsService.createModal(FinanceExportModal, {});
  }
  async function handleAllGoodsDeliveredReport() {
    return ModalsService.createModal(ReportExportModal);
  }
  async function handleInRuWarehouseReport() {
    return ModalsService.createModal(ReportExportModal, {
      reportType: 'in_ru_warehouse',
    });
  }
  async function handleOnWayToRuWarehouseReport() {
    return ModalsService.createModal(ReportExportModal, {
      reportType: 'on_way_to_ru_warehouse',
    });
  }
  async function handleReconciliationAct(kind: 'our' | 'their') {
    return ModalsService.createModal(ReconciliationActModal, { kind });
  }

  async function handleProfitReportModal(
    kind: 'unpaid' | 'without-commission' | 'full' | 'statistics',
  ) {
    return ModalsService.createModal(ProfitReportFromDiscountsModal, { kind });
  }

  async function handleExpenses() {
    return ModalsService.createModal(SupplierExpenseModal);
  }

  async function handlePurchaseReport() {
    return ModalsService.createModal(PurchaseReportModal);
  }

  return (
    <>
      <Header>
        <div className="d-flex flex-row align-items-center">
          <h1>Финансы</h1>
          <Rbac object={RbacObject.Transaction} action={'write:admin'}>
            <div className="ps-4">
              <Dropdown
                toggleClassName="btn btn-success"
                placement="bottomEnd"
                title="Добавить"
                trigger="click"
                onSelect={onSelectAdd}
              >
                <Dropdown.Item eventKey={1}>
                  <i className="bi bi-cash-coin" />
                  <span className="ps-2">Пополнение счета клиента</span>
                </Dropdown.Item>
                <Dropdown.Item eventKey={6}>
                  <i className="bi bi-wallet2" />
                  <span className="ps-2">Списание со счета клиента</span>
                </Dropdown.Item>
                <Dropdown.Separator />
                <Dropdown.Item eventKey={4}>
                  <i className="bi bi-cash-stack" />
                  <span className="ps-2">Пополнение счета контрагента</span>
                </Dropdown.Item>
                <Dropdown.Item eventKey={7}>
                  <i className="bi bi-wallet2" />
                  <span className="ps-2">Списание со счета контрагента</span>
                </Dropdown.Item>
                <Dropdown.Item eventKey={5}>
                  <i className="bi bi-wallet2" />
                  <span className="ps-2">
                    Списание со счета контрагента(Старое)
                  </span>
                </Dropdown.Item>
                <Dropdown.Separator />
                <Dropdown.Item eventKey={3}>
                  <i className="bi bi-cash" />
                  <span className="ps-2">Конвертация валюты клиента</span>
                </Dropdown.Item>
                <Dropdown.Item eventKey={2}>
                  <i className="bi bi-currency-exchange" />
                  <span className="ps-2">Курс валют</span>
                </Dropdown.Item>
                <Dropdown.Separator />
              </Dropdown>
            </div>
          </Rbac>
          <Rbac object={RbacObject.Transaction} action={'read:admin'}>
            <div className="ps-4">
              <div className="btn btn-success" onClick={handleFinanceExport}>
                <i className="bi bi-file-earmark-excel me-2" />
                Экспорт транзакций
              </div>
            </div>
          </Rbac>
          <Rbac object={RbacObject.Transaction} action={'read:admin'}>
            <div className="ps-4">
              <div
                className="btn btn-success"
                onClick={() => handleReconciliationAct('our')}
              >
                <i className="bi bi-file-earmark-excel me-2" />
                Акт сверки
              </div>
            </div>
          </Rbac>
          <Rbac object={RbacObject.Transaction} action={'read:admin'}>
            <div className="ps-4">
              <Dropdown
                toggleClassName="btn btn-success"
                placement="bottomEnd"
                title="Отчёты"
                trigger="click"
              >
                <Dropdown.Item onClick={() => handleReconciliationAct('our')}>
                  <i className="bi bi-file-earmark-excel" />
                  <span className="ps-2">Акт сверки</span>
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey={1}
                  onClick={() => handlePurchaseReport()}
                >
                  <i className="bi bi-file-earmark-excel" />
                  <span className="ps-2">Статистика по выкупам</span>
                </Dropdown.Item>
                <div className="ps-3">Отчёты по доставкам:</div>
                <Dropdown.Item
                  eventKey={5}
                  onClick={() => handleAllGoodsDeliveredReport()}
                >
                  <i className="bi bi-file-earmark-excel" />
                  <span className="ps-2">Все доставки</span>
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey={5}
                  onClick={() => handleInRuWarehouseReport()}
                >
                  <i className="bi bi-file-earmark-excel" />
                  <span className="ps-2">Доставки доставлены на склад РФ</span>
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey={5}
                  onClick={() => handleOnWayToRuWarehouseReport()}
                >
                  <i className="bi bi-file-earmark-excel" />
                  <span className="ps-2">Доставки в пути на склад РФ</span>
                </Dropdown.Item>
                <div className="ps-3">
                  Отчёты о прибыли со скидки в разрезе партнёра:
                </div>
                <Dropdown.Item
                  onClick={() => handleProfitReportModal('unpaid')}
                >
                  <i className="bi bi-file-excel"></i>
                  <span className="ps-2">
                    Выкупы (оплаченные, но не выкупленные)
                  </span>
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleProfitReportModal('without-commission')}
                >
                  <i className="bi bi-file-excel"></i>
                  <span className="ps-2">
                    Доход с выкупленных заказов (без комиссий и доп расходов)
                  </span>
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleProfitReportModal('full')}>
                  <i className="bi bi-file-excel"></i>
                  <span className="ps-2">Доход с выкупленных заказов</span>
                </Dropdown.Item>
              </Dropdown>
            </div>
          </Rbac>
          <Rbac object={RbacObject.Transaction} action={'read:supplier'}>
            <div className="ps-4">
              <div
                className="btn btn-success"
                onClick={() => handleReconciliationAct('their')}
              >
                <i className="bi bi-file-earmark-excel" />
                Акт сверки
              </div>
            </div>
          </Rbac>
          <Rbac object={RbacObject.Transaction} action={'write:expenses'}>
            <div className="ps-4">
              <div className="btn btn-success" onClick={() => handleExpenses()}>
                <i className="bi bi-cash-coin  me-2" />
                Расходы
              </div>
            </div>
          </Rbac>
        </div>
      </Header>
      <MoneyBillsComponent />
      <div className="d-flex flex-row mb-4">
        <ul className="nav nav-pills d-inline-flex">
          {tabs.map(({ path, title, rbac }) => (
            <Rbac object={rbac.object} action={rbac.action} key={path}>
              <li className="nav-item" key={path}>
                <NavLink to={path} className="nav-link text-decoration-none">
                  {title}
                </NavLink>
              </li>
            </Rbac>
          ))}
        </ul>
      </div>
      <Outlet />
    </>
  );
};
