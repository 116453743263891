import React from 'react';
import { StatusBlock } from '../../common/status-block';
import Whisper from 'rsuite/Whisper';
import Tooltip from 'rsuite/Tooltip';
import moment from 'moment';
import { CleanCompositeDelivery } from '../../../export-types/cleaned-types';
import { FormattedMessage } from 'react-intl';

type AdminCompositeDeliveryStatusTableProps = {
  order: CleanCompositeDelivery;
};

export const AdminCompositeDeliveryStatusTable: React.FC<
  AdminCompositeDeliveryStatusTableProps
> = ({ order }) => {
  const deliveryDate = React.useMemo(() => {
    if (!order || !order.deliveryOrders) return undefined;

    const deliveryOrder = order.deliveryOrders[0];

    if (
      deliveryOrder.status !== 'sentToRussia' &&
      deliveryOrder.receiveRfDate
    ) {
      return moment(deliveryOrder.receiveRfDate).format('DD.MM.YYYY, HH:mm');
    }

    if (
      deliveryOrder.status === 'sentToRussia' &&
      !deliveryOrder.receiveRfDate &&
      deliveryOrder.deliveryMethod
    ) {
      const start = moment(deliveryOrder.sentDate)
        .add(deliveryOrder.deliveryMethod.deliveryPeriodFrom, 'd')
        .format('DD.MM.YYYY');
      const end = moment(deliveryOrder.sentDate)
        .add(deliveryOrder.deliveryMethod.deliveryPeriodTo, 'd')
        .format('DD.MM.YYYY');

      return `Получим ${start} - ${end}`;
    }

    return undefined;
  }, [order]);

  const deliveryPeriodTooltip = (
    <Tooltip className="fs-14 text-start lh-sm p-2" style={{ maxWidth: 293 }}>
      Указана ориентировочная дата получения. В случае осуществления таможенной
      проверки груза, срок доставки может быть увеличен до 92 дней.
    </Tooltip>
  );

  return (
    <ul className="d-flex flex-column p-0 mb-0">
      <StatusBlock
        title={
          order.status === 'assembly' ? (
            <FormattedMessage
              defaultMessage="На комплектации"
              id="Vzm5QY"
              description="Статус доставки"
            />
          ) : (
            <FormattedMessage
              defaultMessage="Упакован и отправлен"
              id="kURjOt"
              description="Статус доставки"
            />
          )
        }
        subtitle={
          order.sentDate && moment(order.sentDate).format('DD.MM.YYYY, HH:mm')
        }
        status={
          order.status === 'assembly'
            ? 'active'
            : order.sentDate
              ? 'done'
              : undefined
        }
        connector={true}
        key={'assembly'}
      />

      <StatusBlock
        title={
          order.status === 'sentToRussia' ? (
            <>
              <FormattedMessage
                defaultMessage="В пути на склад в России"
                id="IedHoh"
                description="Статус доставки"
              />
              <Whisper
                placement="right"
                trigger="hover"
                speaker={deliveryPeriodTooltip}
              >
                <i className="ms-2 bi bi-question-circle" />
              </Whisper>
            </>
          ) : (
            <FormattedMessage
              defaultMessage="Прибыл на склад в России"
              id="LozzoB"
              description="Статус доставки"
            />
          )
        }
        subtitle={deliveryDate}
        status={
          order.status === 'sentToRussia'
            ? 'active'
            : order.receiveRfDate
              ? 'done'
              : undefined
        }
        key={'sentToRussia'}
      />
    </ul>
  );
};
