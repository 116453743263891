import airplane from '../assets/airplane.svg';
import train from '../assets/train.svg';
import auto from '../assets/auto.svg';
import PdfIcon from '../assets/pdf-file.svg';
import React from 'react';
import {
  CleanProfile,
  CleanPurchaseOrder,
  CleanDeliveryOrder,
  CleanUser,
} from '../export-types/cleaned-types';
import { FormattedMessage } from 'react-intl';

function getFileApiUrl(key: string) {
  return `/api/file/${key}`;
}

export function getFileV2ApiUrl(id: string, type?: string) {
  return (
    `/api/file/v2/${id}?` +
    new URLSearchParams({
      ...(type ? { type } : undefined),
    })
  );
}

type File = {
  type: string;
  key: string;
};

export function getFileThumbnail(file: string | File) {
  if (
    typeof file === 'string' ||
    typeof file.type === 'undefined' ||
    file.type === 'image'
  ) {
    return getFileApiUrl(typeof file === 'string' ? file : file.key);
  }

  return PdfIcon;
}

export function round2dec(num: number) {
  return Math.round((num + Number.EPSILON) * 100) / 100;
}

export const DECIMAL_PLACES = 6;
export const DECIMAL_STEP = 10 ** -DECIMAL_PLACES;
export function toMoney(num: number | string, currency?: string) {
  let options: Intl.NumberFormatOptions = {
    maximumFractionDigits: DECIMAL_PLACES,
  };

  if (currency && !['usdt', 'bonus'].includes(currency)) {
    options = {
      ...options,
      style: 'currency',
      currency,
      currencyDisplay: 'narrowSymbol',
    };
  }

  let formatted = new Intl.NumberFormat('ru-RU', options).format(Number(num));
  if (currency === 'usdt') {
    formatted = formatted + ' USDT';
  } else if (currency === 'bonus') {
    formatted = formatted + ' бон. руб.';
  }

  return formatted;
}

export type User = {
  role: string;
  accesses: Array<string>;
};

export function userIsClient(
  user: User | undefined | CleanProfile | CleanUser,
) {
  return user?.role === 'client';
}

export function userIsAdmin(user: User | CleanProfile | undefined) {
  return user?.role === 'admin';
}

export function userIsSupplier(user: undefined | CleanProfile) {
  return user?.supplier && user?.role !== 'admin';
}

export function userIsSalesManager(user: undefined | CleanProfile) {
  return user?.accesses?.includes('salesManager') || user?.role === 'admin';
}

export function userIsPurchase(user: User | CleanProfile) {
  return (
    userIsAdmin(user) ||
    Boolean(user?.role === 'employee' && user?.accesses?.includes('purchase'))
  );
}

export function userIsWarehouse(user: User | CleanProfile) {
  return (
    userIsAdmin(user) ||
    Boolean(user?.role === 'employee' && user?.accesses?.includes('warehouse'))
  );
}

export function getDeliveryMethodIcon(method: string) {
  switch (method) {
    case 'air':
      return airplane;
    case 'railway':
      return train;
    case 'auto':
      return auto;
  }
}

export function getCurrencySymbol(currency: string) {
  switch (currency) {
    case 'cny':
      return '¥';
    case 'usd':
      return '$';
    case 'rub':
      return '₽';
  }
}

export function setBodyOverflowHidden() {
  document.body.classList.add('overflow-hidden');
}

export function unsetBodyOverflowHidden() {
  document.body.classList.remove('overflow-hidden');
}

export function getTopUpMethodTitle(name: string) {
  switch (name) {
    case 'sberbank':
      return 'Перевод на карту Сбер';
    case 'alfabank':
      return 'Перевод на карту АльфаБанк';
    case 'tinkoff':
      return 'Перевод на карту Тинькофф';
    case 'alipay':
      return 'Перевод на Alipay';
    case 'wechat':
      return 'Перевод на WeChat';
    case 'cash':
      return 'Наличные (Партнёр)';
    case 'cash-company':
      return 'Наличные (ExpressToday)';
    case 'invoice':
      return 'По счёту для юр. лица';
    case 'pay-keeper_card':
      return 'Онлайн оплата картой';
    case 'pay-keeper_sbp':
      return 'Оплата по СБП';
    case 'cryptomus':
      return 'Оплата криптовалютой';
  }
}

type Period = {
  deliveryPeriodFrom: number | string;
  deliveryPeriodTo?: number | string | null;
};

export function getDeliveryPeriod(period: Period) {
  return (
    <>
      {period.deliveryPeriodFrom}
      <FormattedMessage
        defaultMessage="{value} дней"
        id="delivery.label.days"
        description="Количество дней"
        values={{
          value: period.deliveryPeriodTo ? ` — ${period.deliveryPeriodTo}` : '',
        }}
      />
    </>
  );
}

export type ReconciliationActUrlParams = {
  kind: string;
  currency: 'cny' | 'usd';
  supplierId?: number;
  start?: string;
  end?: string;
  language?: string;
};

export function getReconciliationActUrl({
  kind,
  currency,
  supplierId,
  start,
  end,
  language,
}: ReconciliationActUrlParams) {
  return (
    `/api/supplier/reconciliation-act?` +
    new URLSearchParams({
      kind,
      currency,
      ...(supplierId ? { supplierId: String(supplierId) } : undefined),
      ...(language ? { language: language } : undefined),
      ...(start ? { start } : undefined),
      ...(end ? { end } : undefined),
    })
  );
}

/** Получить ссылку на экспорт всех транзакций в таблице Excel. */
export function getTransactionsExportUrl(start?: string, end?: string) {
  return (
    `/api/transaction/export?` +
    new URLSearchParams({
      ...(start ? { start } : undefined),
      ...(end ? { end } : undefined),
    })
  );
}

export type ProfitReportUrlParams = {
  kind: string;
  suppliers: number[];
  start?: string;
  end?: string;
};

export function getProfitReportUrl({
  kind,
  suppliers,
  start,
  end,
}: ProfitReportUrlParams) {
  const queryParams = new URLSearchParams();

  queryParams.append('kind', kind);

  suppliers.forEach((supplier) => {
    queryParams.append('suppliers[]', String(supplier));
  });

  queryParams.append('start', String(start));
  queryParams.append('end', String(end));

  return `/api/supplier/purchases-report?${queryParams.toString()}`;
}

/** Получить ссылку на скачивание Excel таблицы со всеми доставками. */
export function getDeliveryOrderExportUrl({
  start,
  end,
  status,
  title,
  suppliers,
}: {
  start?: string;
  end?: string;
  status?: CleanDeliveryOrder['status'][];
  title?: string;
  suppliers: number[];
}) {
  return (
    `/api/delivery-order/export?` +
    new URLSearchParams([
      ...(start ? [['start', start]] : []),
      ...(end ? [['end', end]] : []),
      ...(status?.map((name) => ['status[]', name]) || []),
      ...(suppliers?.map((name) => ['suppliers[]', name.toString()]) || []),
      ...(title ? [['title', title]] : []),
    ])
  );
}

/** Получить ссылку на скачивание Excel таблицы со статистикой выкупов. */
export function getPurchaseOrderExportUrl({
  start,
  end,
  status,
  title,
  suppliers,
}: {
  start?: string;
  end?: string;
  status?: CleanPurchaseOrder['status'][];
  title?: string;
  suppliers: number[];
}) {
  return (
    `/api/purchase-order/export/statistics?` +
    new URLSearchParams([
      ...(start ? [['start', start]] : []),
      ...(end ? [['end', end]] : []),
      ...(status?.map((name) => ['status[]', name]) || []),
      ...(suppliers?.map((name) => ['suppliers[]', name.toString()]) || []),
      ...(title ? [['title', title]] : []),
    ])
  );
}

      