import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { api, fetcher } from '../../../services/api';
import useSWR from 'swr';
import { CleanCompositeDelivery } from '../../../export-types/cleaned-types';
import { Select } from '../../common/select.component';
import { useModalClose } from '../../../utils/useModalClose';

type CompositeDeliveryCreateModalProps = {
  close: () => void;
  reject: () => void;
  orders: string[];
};

export const CompositeDeliveryCreateModal: React.FC<
  CompositeDeliveryCreateModalProps
> = ({ close, orders, reject }) => {
  const { handleSubmit, control } = useForm<{
    compositeDeliveryId: string | null;
  }>({
    defaultValues: {
      compositeDeliveryId: null,
    },
  });

  const { data } = useSWR<{
    items: Array<CleanCompositeDelivery>;
    total: number;
  }>(
    () => ({
      url: '/composite-delivery',
      params: {
        status: ['assembly'],
      },
    }),
    fetcher,
  );
  const onSubmit = handleSubmit((data) => {
    if (data.compositeDeliveryId) {
      api
        .post(
          `/composite-delivery/${data.compositeDeliveryId}/purchase-orders`,
          {
            purchaseOrders: orders,
          },
        )
        .then(() => {
          close();
        });
    } else {
      api
        .post(`/composite-delivery`, {
          purchaseOrders: orders,
        })
        .then(() => {
          close();
        });
    }
  });

  const modalRef = useModalClose(reject);

  return (
    <div className="modal-dialog mw-400" ref={modalRef}>
      <div className="modal-content">
        <div className="modal-header border-bottom-0 p-4">
          <button
            type="button"
            className="btn-close"
            onClick={() => reject()}
          />
        </div>
        <form className="modal-body p-4" onSubmit={onSubmit}>
          <div className="text-center mb-4 mx-auto">
            <h3>Создать транзитную посылку</h3>
            <div>
              Создайте новую транзитную посылку или добавьте заказы к
              существующей
            </div>
          </div>
          <Controller
            name="compositeDeliveryId"
            control={control}
            render={({ field, fieldState }) => (
              <Select
                className="mt-3"
                title="Посылка"
                placeholder="Выберите посылку"
                options={
                  data?.items?.map(({ id, internalId }) => ({
                    value: id,
                    label: `T${internalId}`,
                  })) ?? []
                }
                {...field}
                withEmpty={true}
                error={fieldState.error?.message}
              />
            )}
          />
          <button className="btn btn-success w-100 mt-3">Создать</button>
        </form>
      </div>
    </div>
  );
};
