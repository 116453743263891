import React from 'react';
import AsyncSelect from 'react-select/async';
import { fetcher } from '../../services/api';
import { CleanUserAccess } from '../../export-types/cleaned-types';

export type UserWithFilter = {
  id: string | ManagerFilter;
  internalId?: number | null;
  phone?: string;
  name?: string;
  filterCaption?: string;
};

export type ManagerFilter = 'all_users' | 'unattached';

type UserSelectProps = {
  onChange?: (data: UserWithFilter | null) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  name: string;
  placeholder?: string;
  value: UserWithFilter | null;
  additionalOptions: UserWithFilter[];
  access: CleanUserAccess;
  roles?: string[];
};

export function getLoader(
  roles: string[],
  access?: CleanUserAccess,
  additionalOptions: UserWithFilter[] = [],
) {
  return async (inputValue: string) => {
    const users = await fetcher({
      url: '/user',
      params: {
        take: 15,
        roles,
        access,
        query: inputValue || undefined,
      },
    });
    return [...additionalOptions, ...users];
  };
}

export const UserSelectWithFilter = React.forwardRef(function (
  props: UserSelectProps,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  return (
    <AsyncSelect
      name={props.name}
      placeholder={props.placeholder}
      onBlur={props.onBlur}
      loadOptions={getLoader(
        props.roles ?? ['client'],
        props.access,
        props.additionalOptions,
      )}
      cacheOptions
      defaultOptions
      getOptionLabel={(option) => {
        if (option.filterCaption) {
          return option.filterCaption;
        }
        return option.internalId
          ? `ID ${option.internalId} / ${option.phone} / ${option.name}`
          : `${option.phone} / ${option.name}`;
      }}
      getOptionValue={(option) => option.id}
      isMulti={false}
      onChange={props.onChange}
      value={props.value}
      //@ts-ignore
      ref={ref}
    />
  );
});
