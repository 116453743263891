import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { api } from '../../../services/api';
import { InputWrapper } from '../../common/input-wrapper';
import { promocodeValidation, required } from '../../../utils/validations';
import { FormattedMessage, useIntl } from 'react-intl';
import { useModalClose } from '../../../utils/useModalClose';
import { CleanPromotion } from '../../../export-types/cleaned-types';
import { ModalProps } from '../../../services/modals.service';
import { User, UserSelect } from '../../common/user-select';

interface PromotionPromocodeModalProps extends ModalProps {
  promotion: CleanPromotion;
}

export const PromotionPromocodeModal: React.FC<
  PromotionPromocodeModalProps
> = ({ close, promotion, reject }) => {
  const intl = useIntl();
  const { handleSubmit, control } = useForm<{
    promocode: string;
    referrer: User | null;
  }>();

  const onSubmit = handleSubmit((data) => {
    api
      .post(`/promotion/${promotion.id}/promocode`, {
        promocode: data.promocode,
        referrerId: data.referrer?.id ?? undefined,
      })
      .then(() => {
        close();
      });
  });

  const modalRef = useModalClose(reject);

  return (
    <div className="modal-dialog mw-400" ref={modalRef}>
      <div className="modal-content">
        <div className="modal-header border-bottom-0 p-4">
          <button
            type="button"
            className="btn-close"
            onClick={() => reject()}
          />
        </div>
        <form className="modal-body pt-2 p-4" onSubmit={onSubmit}>
          <div className="text-center mb-4 mx-auto">
            <h3 className="mt-3">
              <FormattedMessage
                defaultMessage="Добавить промокод"
                id="promotionPromocodeModal.select-label.addPromocode"
                description="Заголовок в форме промокода акции"
              />
            </h3>
          </div>
          <Controller
            control={control}
            name="promocode"
            rules={{
              ...required,
              ...promocodeValidation,
            }}
            render={({ field, fieldState }) => (
              <InputWrapper
                className="mb-3"
                title={intl.formatMessage({
                  defaultMessage: 'Промокод',
                  id: 'promotionPromocodeModal.select-label.promocode',
                  description: 'Заголовок поля ввода',
                })}
                error={fieldState.error?.message}
              >
                <input type="text" className="form-control" {...field} />
              </InputWrapper>
            )}
          />
          <Controller
            control={control}
            name="referrer"
            render={({ field, fieldState }) => (
              <InputWrapper
                className="mb-3"
                title={intl.formatMessage({
                  defaultMessage: 'Реферал',
                  id: 'promotionPromocodeModal.select-label.referrer',
                  description: 'Заголовок поля ввода',
                })}
                error={fieldState.error?.message}
              >
                <UserSelect
                  placeholder="Выберите клиента"
                  {...field}
                  roles={['client']}
                />
              </InputWrapper>
            )}
          />
          <button className="btn btn-success w-100">
            <FormattedMessage
              defaultMessage="Сохранить"
              id="promotionPromocodeModal.btn-label.save"
              description="Надпись на кнопке"
            />
          </button>
        </form>
      </div>
    </div>
  );
};
