import React, { useEffect, useState } from 'react';
import {
  formatDateValue,
  getCurrentDateValue,
  parseRawDateValue,
  RawDateValue,
} from '../../../utils/date-value';
import { Controller, useForm } from 'react-hook-form';
import isDate from 'validator/lib/isDate';
import {
  getReconciliationActUrl,
  ReconciliationActUrlParams,
} from '../../../utils/common.utils';
import { InputWrapper } from '../../common/input-wrapper';
import { Button, CustomProvider, DatePicker } from 'rsuite';
import { ruRU } from 'rsuite/locales';
import { ModalProps } from '../../../services/modals.service';
import { useSuppliers } from '../../../api/suppliers';
import { Select } from '../../common/select.component';
import { required } from '../../../utils/validations';
import { useModalClose } from '../../../utils/useModalClose';
import { useSelectLanguage } from '../../common/SelectLanguage';
import { periods, Period, useReportLink } from './useReportLink';

type Language = 'ru' | 'en' | 'zh';

const languages: Array<{ title: string; id: Language }> = [
  { title: 'Русский', id: 'ru' },
  { title: 'Английский', id: 'en' },
  { title: 'Китайский', id: 'zh' },
];

type Form = {
  supplierId: number;
  period: Period;
  start?: RawDateValue;
  end?: RawDateValue;
  currency: 'cny' | 'usd';
  language: Language | string;
};

export function isCorrectDate(v: string) {
  return isDate(`${v}`, {
    format: 'DD.MM.YYYY',
    delimiters: ['.'],
    strictMode: true,
  });
}

type Props = ModalProps & {
  kind: 'our' | 'their';
};

export const ReconciliationActModal: React.FC<Props> = ({ close, kind }) => {
  const { data: suppliers } = useSuppliers(kind === 'their');
  const [locale] = useSelectLanguage();

  const {
    control,
    formState: { isValid, errors },
    getValues,
    setValue,
    watch,
    trigger,
  } = useForm<Form>({
    mode: 'all',
    defaultValues: {
      period: 'all_time',
      language: locale ? locale : 'ru',
    },
  });

  useEffect(() => {
    setValue('language', locale);
  }, [locale]);

  const [currency, supplierId, period, customStart, customEnd, language] =
    watch(['currency', 'supplierId', 'period', 'start', 'end', 'language']);

  React.useEffect(() => {
    trigger(['start', 'end']);
  }, [trigger, customStart, customEnd]);

  const minDate = React.useMemo(
    () =>
      formatDateValue(
        getCurrentDateValue().subtract(1, 'year').startOf('year'),
      ),
    [],
  );
  const maxDate = React.useMemo(
    () => formatDateValue(getCurrentDateValue()),
    [],
  );
  const params: ReconciliationActUrlParams = {
    kind,
    currency,
    supplierId,
    language,
  };

  const reportLink = useReportLink(
    isValid,
    period,
    params,
    //@ts-expect-error
    getReconciliationActUrl as (params: ReconciliationActUrlParams) => string,
    customStart,
    customEnd,
  );

  const modalRef = useModalClose(close);

  return (
    <CustomProvider locale={ruRU}>
      <div className="modal-dialog" ref={modalRef}>
        <form className="modal-content">
          <div className="modal-body">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <h3>Акт сверки</h3>
              <div className="btn p-0" onClick={close}>
                <i className="bi bi-x fs-36 color-gray-600" />
              </div>
            </div>
            {kind === 'our' ? (
              <Controller
                name="supplierId"
                control={control}
                rules={required}
                render={({ field }) => (
                  <Select
                    className="mb-3"
                    title="Поставщик"
                    placeholder="Выберите поставщика"
                    required
                    options={
                      suppliers?.map(({ id, name }) => ({
                        value: id,
                        label: name,
                      })) ?? []
                    }
                    {...field}
                    error={errors.supplierId?.message}
                  />
                )}
              />
            ) : null}
            <Controller
              name="currency"
              control={control}
              rules={required}
              render={({ field }) => (
                <Select
                  className="mb-3"
                  title="Валюта"
                  placeholder="Выберите валюту"
                  required
                  options={[
                    { value: 'cny', label: 'Юань' },
                    { value: 'usd', label: 'Доллар' },
                  ]}
                  {...field}
                  error={errors.currency?.message}
                />
              )}
            />
            <Controller
              name="period"
              control={control}
              rules={{
                required: 'Укажите период',
              }}
              render={({ field, fieldState }) => {
                return (
                  <InputWrapper
                    required
                    title="Выберите период"
                    error={fieldState.error?.message}
                    className="mb-3"
                  >
                    <select className="form-select" {...field}>
                      {periods.map((item) => {
                        return (
                          <option
                            value={item.id}
                            key={item.id}
                            className="mb-1 cursor-pointer bg-white py-3 pl-3 pr-7 text-base-lh-125 font-medium text-gray-800 hover:bg-green-100"
                          >
                            {item.title}
                          </option>
                        );
                      })}
                    </select>
                  </InputWrapper>
                );
              }}
            />
            {period === 'range' && (
              <div className="w-full sm:flex sm:items-start sm:justify-between">
                <Controller
                  name="start"
                  control={control}
                  rules={{
                    validate: (value) => {
                      if (!value) return true;

                      if (!isCorrectDate(value))
                        return 'Укажите корректную дату';

                      const end = getValues('end');

                      if (!end || !isCorrectDate(end)) return true;

                      if (
                        parseRawDateValue(value).isAfter(parseRawDateValue(end))
                      )
                        return 'Дата не должна быть позже окончания';

                      return true;
                    },
                  }}
                  render={({ field, fieldState }) => {
                    return (
                      <InputWrapper
                        title="Дата начала"
                        error={fieldState.error?.message}
                        className="mb-2 flex-1 text-base-lh-100 sm:mb-0 sm:mr-4"
                      >
                        <DatePicker
                          className={'w-100'}
                          format={'dd.MM.yyyy'}
                          isoWeek={true}
                          shouldDisableDate={(d) => {
                            const fieldDate = customEnd
                              ? parseRawDateValue(customEnd).toDate()
                              : undefined;
                            if (fieldDate) {
                              return (
                                d < parseRawDateValue(minDate).toDate() ||
                                d > fieldDate
                              );
                            } else {
                              return (
                                d < parseRawDateValue(minDate).toDate() ||
                                d > parseRawDateValue(maxDate).toDate()
                              );
                            }
                          }}
                          onChange={(v) => {
                            if (v === null) {
                              field.onChange(null);
                            } else {
                              field.onChange(formatDateValue(v));
                            }
                          }}
                          value={
                            field.value
                              ? parseRawDateValue(field.value).toDate()
                              : undefined
                          }
                        />
                      </InputWrapper>
                    );
                  }}
                />
                <Controller
                  name="end"
                  control={control}
                  rules={{
                    validate: (value) => {
                      if (!value) return true;

                      if (!isCorrectDate(value))
                        return 'Укажите корректную дату';

                      const start = getValues('start');

                      if (!start || !isCorrectDate(start)) return true;

                      if (
                        parseRawDateValue(value).isBefore(
                          parseRawDateValue(start),
                        )
                      )
                        return 'Дата не должна быть раньше начальной';

                      return true;
                    },
                  }}
                  render={({ field, fieldState }) => {
                    return (
                      <InputWrapper
                        title="Дата окончания"
                        error={fieldState.error?.message}
                        className="mb-2 flex-1 text-base-lh-100 sm:mb-0"
                      >
                        <DatePicker
                          className={'w-100'}
                          shouldDisableDate={(d) => {
                            const fieldDate = customStart
                              ? parseRawDateValue(customStart).toDate()
                              : undefined;
                            if (fieldDate) {
                              return (
                                d < fieldDate ||
                                d > parseRawDateValue(maxDate).toDate()
                              );
                            } else {
                              return (
                                d < parseRawDateValue(minDate).toDate() ||
                                d > parseRawDateValue(maxDate).toDate()
                              );
                            }
                          }}
                          format={'dd.MM.yyyy'}
                          isoWeek={true}
                          onChange={(v) => {
                            if (v === null) {
                              field.onChange(null);
                            } else {
                              field.onChange(formatDateValue(v));
                            }
                          }}
                          value={
                            field.value
                              ? parseRawDateValue(field.value).toDate()
                              : undefined
                          }
                        />
                      </InputWrapper>
                    );
                  }}
                />
              </div>
            )}

            <Controller
              name="language"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <InputWrapper
                    required
                    title="Выберите язык"
                    error={fieldState.error?.message}
                    className="mb-3"
                  >
                    <select className="form-select" {...field}>
                      {languages.map((item) => {
                        return (
                          <option
                            value={item.id}
                            key={item.id}
                            className="mb-1 cursor-pointer bg-white py-3 pl-3 pr-7 text-base-lh-125 font-medium text-gray-800 hover:bg-green-100"
                          >
                            {item.title}
                          </option>
                        );
                      })}
                    </select>
                  </InputWrapper>
                );
              }}
            />
          </div>
          <div className="modal-footer justify-content-end">
            {reportLink ? (
              <Button
                as="a"
                href={reportLink}
                target="_blank"
                className="btn btn-success"
              >
                Скачать в Excel
              </Button>
            ) : (
              <Button as="button" disabled={true} className="btn btn-success">
                Скачать в Excel
              </Button>
            )}
          </div>
        </form>
      </div>
    </CustomProvider>
  );
};
