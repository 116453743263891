import React from 'react';
import { InputWrapper } from '../../common/input-wrapper';
import { Gallery } from '../../common/gallery/gallery';
import {
  getFileV2ApiUrl,
  toMoney,
  userIsAdmin,
} from '../../../utils/common.utils';
import { Controller, useForm } from 'react-hook-form';
import { intValidation, required } from '../../../utils/validations';
import Popover from 'rsuite/Popover';
import Dropdown from 'rsuite/Dropdown';
import { AlertService } from '../../../services/alert.service';
import { api, fetcher } from '../../../services/api';
import Whisper from 'rsuite/Whisper';
import useSWR from 'swr';
import { uploadMediaInputFiles } from '../../../services/file-service';
import { MediaInput, MediaInputValue } from '../../common/media-input';
import { useProfile } from '../../../api/profile';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  CleanPurchaseOrder,
  RbacObject,
} from '../../../export-types/cleaned-types';
import { Rbac } from '../../common/Rbac';
import { ModalsService } from '../../../services/modals.service';
import { ParcelCancelModal } from './parcel-cancel-modal';

type RequestInfoProps = {
  id: string;
  actions?: React.ReactNode;
};

type CleanParcel = CleanPurchaseOrder & {
  trackNumber: string;
  productPrice: string;
  requestCount: number;
  comment: string;
  name: string;
};

export const ParcelInfo: React.FC<RequestInfoProps> = (props) => {
  const intl = useIntl();
  const { data: user } = useProfile();
  const { data: parcel, mutate: parcelMutate } = useSWR<CleanParcel>(
    `/parcels/${props.id}`,
    fetcher,
  );

  const [editMode, setEditMode] = React.useState(false);

  if (!parcel || !user) {
    return null;
  }

  async function handleChange() {
    await parcelMutate();
  }

  async function handleSave(data: CleanParcel) {
    setEditMode(false);
    await parcelMutate(data);
  }

  function handleEdit() {
    setEditMode(true);
  }

  function handleCancel() {
    setEditMode(false);
  }

  if (editMode) {
    return (
      <InfoEditForm
        id={parcel.id}
        defaultValues={{
          trackNumber: parcel.trackNumber,
          productPrice: parcel.productPrice,
          requestCount: String(parcel.requestCount),
          requestPlaces: String(parcel.requestPlaces),
          requestFiles: parcel.requestFilesV2.map((file) => ({
            type: 'exist',
            file,
          })),
          comment: parcel.comment,
        }}
        onSave={handleSave}
        onCancel={handleCancel}
      />
    );
  }

  const currencySymbol = parcel.supplier?.purchaseCurrency || 'cny';

  return (
    <div>
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="fs-18 fw-600 color-gray-450">
          <FormattedMessage
            defaultMessage="О заказе"
            id="orderInfo.label.aboutOrder"
            description="Информация о заказе"
          />
        </div>
        {parcel.status !== 'canceled' && userIsAdmin(user) && (
          <RequestInfoActions
            order={parcel}
            onChange={handleChange}
            onEdit={handleEdit}
          />
        )}
      </div>
      {parcel.name && (
        <div className="mt-3">
          <div className="mb-1 color-gray-600">
            <FormattedMessage
              defaultMessage="Название"
              id="parcelInfo.label.name"
              description="Подзаголовок в карточке"
            />
          </div>
          <div className="text-black">{parcel.name}</div>
        </div>
      )}
      {parcel.trackNumber && (
        <InputWrapper
          theme="light"
          className="mt-3 text-truncate"
          title={intl.formatMessage({
            defaultMessage: 'Трек-номер',
            id: 'parcelInfo.label.trackNumber',
            description: 'Информация о трек-номере',
          })}
        >
          {parcel.trackNumber}
        </InputWrapper>
      )}
      {parcel.requestPlaces && (
        <InputWrapper
          theme="light"
          className="mt-3 text-truncate"
          title={intl.formatMessage({
            defaultMessage: 'Количество мест',
            id: 'deliveryAssemblyModal.input-label.amountOfPlaces',
            description: 'Подзаголовок в карточке',
          })}
        >
          {parcel.requestPlaces}
        </InputWrapper>
      )}
      {parcel.requestCount && (
        <InputWrapper
          theme="light"
          className="mt-3 text-truncate"
          title={intl.formatMessage({
            defaultMessage: 'Ожидаемое количество товара',
            id: 'parcelInfo.label.expectedQuantityOfGoods',
            description: 'Подзаголовок в карточке',
          })}
        >
          <FormattedMessage
            defaultMessage=" {value} шт"
            id="inputValue.label.pcs"
            description="Единица измерения"
            values={{ value: parcel.requestCount }}
          />
        </InputWrapper>
      )}
      {parcel.productPrice && (
        <InputWrapper
          theme="light"
          className="mt-3 text-truncate"
          title={intl.formatMessage({
            defaultMessage: 'Стоимость товаров',
            id: 'parcelInfo.label.priceOfGoods',
            description: 'Подзаголовок в карточке',
          })}
        >
          {toMoney(parcel.productPrice, currencySymbol)}
        </InputWrapper>
      )}
      {parcel.requestFilesV2.length > 0 && (
        <InputWrapper
          theme="light"
          className="mt-3"
          title={intl.formatMessage({
            defaultMessage: 'Фотографии товаров',
            id: 'parcelInfo.label.photosOfGoods',
            description: 'Подзаголовок в карточке',
          })}
        >
          <Gallery className="mt-2">
            {parcel.requestFilesV2.map((file, index) => (
              <a href={getFileV2ApiUrl(file.id)} key={index}>
                <img
                  className="rounded fit-cover me-2"
                  src={getFileV2ApiUrl(file.id, '64x64')}
                  width={64}
                  height={64}
                  alt=""
                />
              </a>
            ))}
          </Gallery>
        </InputWrapper>
      )}
      {parcel.comment && (
        <InputWrapper
          theme="light"
          className="mt-3"
          title={intl.formatMessage({
            defaultMessage: 'Комментарий',
            id: 'purchaseOrderRequest.label-input.comment',
            description: 'Поле для комментария',
          })}
        >
          <div>{parcel.comment}</div>
        </InputWrapper>
      )}
    </div>
  );
};

type InfoEditFormProps = {
  id: string;
  defaultValues: {
    trackNumber: string;
    requestCount: string;
    requestPlaces: string;
    productPrice: string;
    requestFiles: MediaInputValue[];
    comment: string;
  };
  onSave: (data: CleanParcel) => void;
  onCancel: () => void;
};

const InfoEditForm: React.FC<InfoEditFormProps> = (props) => {
  const intl = useIntl();

  const { control, register, handleSubmit, formState } = useForm<{
    trackNumber: string;
    requestCount: string;
    requestPlaces: string;
    productPrice: string;
    requestFiles: MediaInputValue[];
    comment: string;
  }>({
    defaultValues: props.defaultValues,
  });

  const onSubmit = handleSubmit(async (form) => {
    const requestFilesResponse = await uploadMediaInputFiles(form.requestFiles);

    api
      .put(`/parcels/admin/${props.id}/request`, {
        trackNumber: form.trackNumber.trim(),
        requestCount: form.requestCount ? Number(form.requestCount) : null,
        requestPlaces: Number(form.requestPlaces),
        productPrice: form.productPrice,
        requestFiles: requestFilesResponse.map((response) => ({
          id: response.file.id,
        })),
        comment: form.comment.trim(),
      })
      .then(({ data }: { data: { parcel: CleanParcel } }) => {
        AlertService.success();
        props.onSave(data.parcel);
      });
  });

  function handleCancel() {
    props.onCancel();
  }

  return (
    <form onSubmit={onSubmit}>
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="fs-18 fw-600 color-gray-450">О заказе</div>
        <div>
          <button
            className="btn btn-link text-decoration-none"
            disabled={formState.isSubmitting}
          >
            {formState.isSubmitting ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              'Сохранить'
            )}
          </button>
          <button
            className="btn btn-link text-decoration-none"
            type="button"
            disabled={formState.isSubmitting}
            onClick={handleCancel}
          >
            Отменить
          </button>
        </div>
      </div>
      <InputWrapper
        theme="light"
        className="mt-3"
        title="Трек-номер"
        error={formState.errors.trackNumber?.message}
      >
        <input
          type="text"
          className="form-control"
          {...register('trackNumber', { ...required })}
        />
      </InputWrapper>
      <InputWrapper
        theme="light"
        className="mt-3"
        title="Количество мест"
        error={formState.errors.requestPlaces?.message}
      >
        <div className="input-group">
          <input
            className="form-control"
            type="number"
            min={1}
            step={1}
            {...register('requestPlaces', {
              ...required,
              ...intValidation,
            })}
          />
        </div>
      </InputWrapper>
      <InputWrapper
        theme="light"
        className="mt-3"
        title="Ожидаемое количество товара"
      >
        <div className="input-group">
          <input
            className="form-control"
            type="number"
            min={1}
            step={1}
            {...register('requestCount')}
          />
          <span className="input-group-text">
            <FormattedMessage
              defaultMessage="шт"
              id="input.label.pcs"
              description="Единица измерения"
            />
          </span>
        </div>
      </InputWrapper>
      <InputWrapper
        theme="light"
        className="mt-3"
        title="Стоимость товаров"
        error={formState.errors.productPrice?.message}
      >
        <div className="input-group">
          <input
            className="form-control"
            type="number"
            min={1}
            step={0.01}
            {...register('productPrice', {
              ...required,
              ...intValidation,
            })}
          />
          <span className="input-group-text">¥</span>
        </div>
        <div className="fs-14 color-gray-400 mt-1">
          Необходима для расчёта страховки при оформлении доставки.
        </div>
      </InputWrapper>
      <Controller
        control={control}
        rules={{ ...required }}
        name="requestFiles"
        render={({ field, fieldState }) => (
          <InputWrapper
            theme="light"
            className="mt-3"
            title="Фотографии товаров"
            required
            error={fieldState.error?.message}
          >
            <MediaInput {...field} className="mt-2" multiple />
          </InputWrapper>
        )}
      />
      <InputWrapper
        theme="light"
        className="mt-3"
        title={intl.formatMessage({
          defaultMessage: 'Комментарий',
          id: 'purchaseOrderRequest.label-input.comment',
          description: 'Поле для комментария',
        })}
      >
        <textarea className="form-control" {...register('comment')} />
      </InputWrapper>
    </form>
  );
};

type RequestInfoActionsProps = {
  order: CleanParcel;
  onChange: () => void;
  onEdit: () => void;
};

const RequestInfoActions: React.FC<RequestInfoActionsProps> = (props) => {
  const speaker = (
    { onClose, left, top, className }: any,
    ref: React.Ref<any>,
  ) => (
    <Popover ref={ref} className={className} style={{ left, top }} full>
      <Dropdown.Menu>
        <Dropdown.Item
          onSelect={function () {
            onClose();
            props.onEdit();
          }}
        >
          <i className="bi bi-pencil" />
          <span className="ps-2">Изменить</span>
        </Dropdown.Item>
        <Rbac object={RbacObject.Parcel} action={'cancel'}>
          <Dropdown.Item
            onSelect={function () {
              onClose();
              ModalsService.createModal(ParcelCancelModal, {
                order: { id: props.order.id },
              }).then(() => {
                AlertService.success('Входящая посылка успешно отменена');
                props.onChange();
              });
            }}
          >
            <i className="bi bi-x-circle" />
            <span className="ps-2">Отменить</span>
          </Dropdown.Item>
        </Rbac>
      </Dropdown.Menu>
    </Popover>
  );

  return (
    <Whisper placement="bottomEnd" trigger="click" speaker={speaker}>
      <button className="btn btn-link text-decoration-none">
        <i className="bi bi-three-dots" />
      </button>
    </Whisper>
  );
};
