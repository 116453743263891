import { Header } from '../../common/header.component';
import { Input } from '../../common/input.component';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import React from 'react';
import { CleanLocalDeliveryMethod } from '../../../export-types/cleaned-types';
import { api } from '../../../services/api';
import { AlertService } from '../../../services/alert.service';
import { useSuppliers } from '../../../api/suppliers';
import { required } from '../../../utils/validations';
import { Select } from '../../common/select.component';
import { useLocations } from '../../../api/locations';
import Toggle from 'rsuite/Toggle';

type FieldValues = {
  name: string;
  slug: string;
  priority: number | null;
  supplierId: number;
  locationId: number;
  selfDelivery: boolean;
  priceClient?: string;
  commentClient?: string | null;
  priceSupplier?: string;
  commentSupplier?: string | null;
};

export const LocalDeliveryMethodScreen = () => {
  const { data: suppliers } = useSuppliers();
  const { data: locations } = useLocations();
  const navigate = useNavigate();
  const { state } = useLocation() as {
    state?: Partial<CleanLocalDeliveryMethod>;
  };

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<FieldValues>({
    defaultValues: {
      name: state?.name,
      slug: state?.slug,
      priority: state?.priority,
      supplierId: state?.supplier?.id,
      locationId: state?.location?.id,
      selfDelivery: state?.selfDelivery ?? false,
      priceClient: state?.priceClient,
      commentClient: state?.commentClient,
      priceSupplier: state?.priceSupplier,
      commentSupplier: state?.commentSupplier,
    },
  });

  function onSave(data: FieldValues) {
    const id = state?.id;

    let promise;
    if (id) {
      if (data.priceClient === '') {
        data.priceClient = '0';
      }
      if (data.priceSupplier === '') {
        data.priceSupplier = '0';
      }
      promise = api.put(`/local-delivery-method/${id}`, data);
    } else {
      promise = api.post(`/local-delivery-method`, data);
    }

    promise.then(() => {
      AlertService.success();
      navigate('/deliveries/local-delivery');
    });
  }

  return (
    <>
      <Header />
      <div className="container" style={{ marginTop: -70 }}>
        <h1 className="mb-4">Добавить транспортную компанию</h1>
        <form onSubmit={handleSubmit(onSave)}>
          <Controller
            name="supplierId"
            control={control}
            rules={required}
            render={({ field }) => (
              <Select
                className="mb-3"
                title="Поставщик"
                placeholder="Выберите поставщика"
                options={
                  suppliers?.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  })) ?? []
                }
                {...field}
                error={errors.supplierId?.message}
              />
            )}
          />
          <Controller
            name="locationId"
            control={control}
            rules={required}
            render={({ field }) => (
              <Select
                className="mb-3"
                title="Откуда"
                placeholder="Выберите локацию"
                options={
                  locations?.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  })) ?? []
                }
                {...field}
                error={errors.locationId?.message}
              />
            )}
          />
          <div className="row">
            <div className="col-6 mb-3">
              <Input
                title="Название"
                placeholder="Например, СДЭК"
                {...register('name', { required: 'Заполните поле' })}
                error={errors.name?.message}
              />
              <div className="fs-14 color-gray-400">
                Это название отобразится у клиента
              </div>
            </div>
          </div>
          {!state?.id ? (
            <div className="row">
              <div className="col-6 mb-3">
                <Input
                  title="Псевдоним"
                  {...register('slug', { required: 'Заполните поле' })}
                  error={errors.slug?.message}
                />
                <div className="fs-14 color-gray-400">
                  Внутреннее обозначение в БД
                </div>
              </div>
            </div>
          ) : null}
          <div className="row">
            <div className="col-6 mb-3">
              <Input
                title="Приоритет"
                type="number"
                step="1"
                {...register('priority')}
                error={errors.priority?.message}
              />
              <div className="fs-14 color-gray-400">
                Влияет на сортировку. Чем меньше, тем выше в списке
              </div>
            </div>
            <Controller
              name="selfDelivery"
              control={control}
              render={({ field }) => (
                <div className="d-flex mb-3">
                  <Toggle checked={field.value} onChange={field.onChange} />
                  <div className="ms-2">Самовывоз</div>
                </div>
              )}
            />
            <p className="mb-2 mt-2">Дополнительные расходы связанные с ТК:</p>
            <div className="row">
              <div className="col-6 mb-3">
                <Input
                  title="Цена Клиент"
                  type="number"
                  placeholder=""
                  {...register('priceClient')}
                  error={errors.priceClient?.message}
                />
              </div>
              <div className="col-6 mb-3">
                <Input
                  title="Комментарий"
                  placeholder=""
                  {...register('commentClient')}
                  error={errors.commentClient?.message}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6 mb-3">
                <Input
                  title="Цена Поставщик"
                  type="number"
                  placeholder=""
                  {...register('priceSupplier')}
                  error={errors.priceSupplier?.message}
                />
              </div>
              <div className="col-6 mb-3">
                <Input
                  title="Комментарий"
                  placeholder=""
                  {...register('commentSupplier')}
                  error={errors.commentSupplier?.message}
                />
              </div>
            </div>
            <div className="col-12 pt-3 mb-4">
              <button type="submit" className="btn btn-success w-100">
                Сохранить
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
