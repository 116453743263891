import React from 'react';
import { Header } from '../../common/header.component';
import { NavLink, Outlet } from 'react-router-dom';
import useSWR from 'swr';
import { fetcher } from '../../../services/api';
import { useDebouncedState } from '@mantine/hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import Toggle from 'rsuite/Toggle';
import { RbacObject } from '../../../export-types/cleaned-types';
import { Rbac } from '../../common/Rbac';
import {
  SupplierSelect,
  useSelectSupplierQs,
} from '../purchase-order/supplier-select';
import { useSelectSupplier } from '../purchase-order/supplier-select';

type ParcelProps = {
  status: 'onTheWayToStock' | 'receivedInStock' | 'sentToRussia' | 'canceled';
};

export type OutletContextParams = {
  debouncedInternalId: string;
  linked: boolean;
  selectSupplierId: number | undefined;
};

export const EmployeeParcelsScreen = () => {
  const intl = useIntl();
  const tabs = [
    {
      title: intl.formatMessage({
        id: 'tabs.lable.awaitingReceipt',
        defaultMessage: 'Ожидают получения',
        description: 'Надпись на вкладке',
      }),
      path: 'on-the-way',
      name: 'onTheWayToStock' as const,
    },
    {
      title: intl.formatMessage({
        id: 'tabs.lable.inStock',
        defaultMessage: 'На складе',
        description: 'Надпись на вкладке',
      }),
      path: 'received-in-stock',
      name: 'receivedInStock' as const,
    },
    {
      title: intl.formatMessage({
        id: 'tabs.lable.archive',
        defaultMessage: 'Архив',
        description: 'Надпись на вкладке',
      }),
      path: 'archive',
      name: 'archive' as const,
    },
  ];
  const [internalId, setInternalId] = useDebouncedState('', 200);
  const [linked, setLinked] = React.useState(false);
  const [selectSupplier] = useSelectSupplier();
  const [supplierId] = useSelectSupplierQs();
  console.log('supplierId', supplierId);
  console.log('selectSupplier', selectSupplier);
  const { data, isLoading } = useSWR<{
    items: Record<ParcelProps['status'], number | undefined>;
  }>(
    {
      url: '/parcels/total-by-status',
      params: {
        linked,
        internalId: internalId ? internalId : undefined,
        supplierId: selectSupplier,
      },
    },
    fetcher,
  );

  const count = React.useMemo(() => {
    if (!data) {
      return {};
    }
    return {
      onTheWayToStock: data.items.onTheWayToStock || 0,
      receivedInStock: data.items.receivedInStock || 0,
      archive: (data.items.sentToRussia || 0) + (data.items.canceled || 0),
    };
  }, [data]);

  function renderTabCount(
    item: typeof tabs extends Array<infer T> ? T : never,
  ) {
    if (isLoading) {
      return (
        <>
          (<i className="bi bi-three-dots color-gray-400" />)
        </>
      );
    }

    if (typeof count[item.name] === 'undefined') {
      return null;
    }

    return `(${count[item.name]})`;
  }

  const context: OutletContextParams = {
    debouncedInternalId: internalId,
    linked,
    selectSupplierId: selectSupplier,
  };

  return (
    <>
      <Header>
        <div className="d-flex flex-row">
          <h1>
            <FormattedMessage
              defaultMessage="Входящие посылки"
              id="parcel.label.incomingParcels"
              description="Заголовок страницы"
            />
          </h1>
        </div>
      </Header>
      <div className="d-flex justify-content-between align-items-center pb-4 ">
        <div className="d-flex w-75 mr-auto">
          <input
            className="form-control w-25"
            type="text"
            placeholder={intl.formatMessage({
              defaultMessage: 'Поиск по номеру заказа/клиента',
              id: 'input.placeholder.searchByOrder/CustomerNumber',
              description: 'Поле ввода',
            })}
            onChange={(event) => setInternalId(event.currentTarget.value)}
          />
          <Rbac object={RbacObject.Supplier} action={'read:admin'}>
            <SupplierSelect />
          </Rbac>
        </div>
        <div className="d-flex">
          <Toggle checked={linked} onChange={() => setLinked(!linked)} />
          <div className="ms-2">
            <FormattedMessage
              defaultMessage="Мои клиенты"
              id="switchButton.label.myCustomers"
              description="Надпись на переключателе"
            />
          </div>
        </div>
      </div>

      <ul className="nav nav-pills mb-4 d-inline-flex">
        {tabs.map((item) => (
          <li className="nav-item" key={item.title}>
            <NavLink to={item.path} className="nav-link text-decoration-none">
              {item.title} {renderTabCount(item)}
            </NavLink>
          </li>
        ))}
      </ul>
      <Outlet context={context} />
    </>
  );
};
