import React from 'react';
import { CleanLocalDeliveryMethod } from '../../../export-types/cleaned-types';
import { FormattedMessage } from 'react-intl';

type LocalDeliveryProps = {
  localDelivery: CleanLocalDeliveryMethod;
};

export const LocalDelivery: React.FC<LocalDeliveryProps> = ({
  localDelivery,
}) => {
  return (
    <div>
      <div className="color-gray-800 fs-16 fw-500">{localDelivery.name}</div>
      <div className="color-gray-600 fs-14 fw-400">
        <FormattedMessage
          defaultMessage="ТК по РФ"
          id="delivery.label.shippingCompanyWithinTheRussianFederation"
          description="Информация от ТК"
        />
      </div>
    </div>
  );
};
