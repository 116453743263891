import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  decimalValidation,
  intValidation,
  required,
} from '../../../utils/validations';
import { AlertService } from '../../../services/alert.service';
import { uploadMediaInputFiles } from '../../../services/file-service';
import { api, fetcher } from '../../../services/api';
import { InputWrapper } from '../../common/input-wrapper';
import { MediaInput, MediaInputValue } from '../../common/media-input';
import { FormattedMessage, useIntl } from 'react-intl';
import { getCurrencySymbol } from '../../../utils/common.utils';
import { CleanPurchaseOrder } from '../../../export-types/cleaned-types';
import { Select } from '../../common/select.component';
import { trpcSwr } from '../../../api/trpc';

type PurchaseOrderPurchaseConfirmationModalProps = {
  close: () => void;
  reject: () => void;
  order: CleanPurchaseOrder;
};

type Form = {
  moderateFinalPrice: string;
  moderateDeliveryPrice: string;
  receiptFiles: MediaInputValue[];
  serviceChargeOption?: string;
  orderLink: string;
  orderNumber: string;
  orderAccount: string;
  landingBill: string;
  transportCompany: string;
};

export const PurchaseOrderPurchaseConfirmationModal: React.FC<
  PurchaseOrderPurchaseConfirmationModalProps
> = ({ order, ...props }) => {
  const { data } =
    trpcSwr.serviceChargeOption.supplier.listPurchaseConfirmation.useSWR({
      purchaseId: order.id,
    });

  const {
    register,
    formState: { errors, isSubmitting },
    control,
    handleSubmit,
    watch,
  } = useForm<Form>();

  const intl = useIntl();

  const [moderateFinalPrice, moderateDeliveryPrice] = watch([
    'moderateFinalPrice',
    'moderateDeliveryPrice',
  ]);
  const productPrice = React.useMemo(() => {
    if (moderateFinalPrice && moderateDeliveryPrice) {
      return Number(moderateFinalPrice) - Number(moderateDeliveryPrice);
    }
    return '';
  }, [moderateFinalPrice, moderateDeliveryPrice]);

  const onSubmit = handleSubmit(async (data) => {
    const receiptFilesResponse = await uploadMediaInputFiles(data.receiptFiles);

    await api.post(`/purchase-order/v2/${order.id}/purchase-confirmation`, {
      moderateFinalPrice: data.moderateFinalPrice,
      moderateDeliveryPrice: data.moderateDeliveryPrice,
      receiptFiles: receiptFilesResponse.map((response) => ({
        id: response.file.id,
      })),
      serviceChargeOption: data.serviceChargeOption || undefined,
      orderLink: data.orderLink || undefined,
      orderNumber: data.orderNumber || undefined,
      orderAccount: data.orderAccount || undefined,
      landingBill: data.landingBill || undefined,
      transportCompany: data.transportCompany || undefined,
    });

    AlertService.success();
    props.close();
  });

  const currencySymbol = getCurrencySymbol(
    order.supplier?.purchaseCurrency || 'cny',
  );

  return (
    <div className="modal-dialog">
      <form className="modal-content" onSubmit={onSubmit}>
        <div className="modal-header border-bottom-0 p-4">
          <h3>
            <FormattedMessage
              defaultMessage="Подтвердить выкуп заказа"
              id="purchaseOrderModal.label.confirmOrderRedemption"
              description="Заголовок модального окна"
            />
          </h3>
          <button
            type="button"
            className="btn-close"
            onClick={() => props.reject()}
          />
        </div>

        <div className="modal-body pt-2 p-4">
          <Controller
            control={control}
            name="receiptFiles"
            rules={{
              required: 'Загрузите хотя бы один скриншот',
            }}
            render={({ field, fieldState }) => (
              <InputWrapper
                title={intl.formatMessage({
                  defaultMessage: 'Скриншот выкупа с итоговой суммой',
                  id: 'purchaseOrderModal.input-label.screenshotFinalAmount',
                  description: 'Заголовок поля ввода',
                })}
                className="mb-3"
                error={fieldState.error?.message}
                required
              >
                <MediaInput {...field} multiple />
              </InputWrapper>
            )}
          />

          <h4 className="mt-3">
            <FormattedMessage
              defaultMessage="Информация о выкупе"
              id="purchaseOrderModal.label.redemptionInformation"
              description="Подзаголовок модального окна"
            />
          </h4>

          <InputWrapper
            title={intl.formatMessage({
              defaultMessage: 'Итоговая цена (со скидкой)',
              id: 'purchaseOrderModal.input-label.totalPrice',
              description: 'Заголовок поля ввода',
            })}
            className="mb-3"
            error={errors.moderateFinalPrice?.message}
            required
          >
            <div className="input-group">
              <input
                className="form-control"
                type="number"
                min={0}
                step={0.01}
                {...register('moderateFinalPrice', {
                  ...required,
                  ...decimalValidation,
                })}
              />
              <span className="input-group-text">{currencySymbol}</span>
            </div>
          </InputWrapper>

          <InputWrapper
            title={intl.formatMessage({
              defaultMessage: 'В т.ч. доставка',
              id: 'purchaseOrderModal.input-label.includingDelivery',
              description: 'Заголовок поля ввода',
            })}
            className="mb-3"
            error={errors.moderateDeliveryPrice?.message}
            required
          >
            <div className="input-group">
              <input
                className="form-control"
                type="number"
                min={0}
                step={0.01}
                {...register('moderateDeliveryPrice', {
                  ...required,
                  ...decimalValidation,
                })}
              />
              <span className="input-group-text">{currencySymbol}</span>
            </div>
          </InputWrapper>

          <InputWrapper
            title={intl.formatMessage({
              defaultMessage: 'В т.ч. за товары',
              id: 'purchaseOrderModal.input-label.includingForGoods',
              description: 'Заголовок поля ввода',
            })}
            className="mb-3"
          >
            <div className="input-group">
              <input
                className="form-control"
                type="text"
                disabled
                value={productPrice}
              />
              <span className="input-group-text">{currencySymbol}</span>
            </div>
            <div className="fs-14 color-gray-400">
              <FormattedMessage
                defaultMessage="Рассчитывается автоматически (итоговая цена - доставка)"
                id="purchaseOrderModal.label.calculatedAutomatically"
                description="Пояснение для поля ввода"
              />
            </div>
          </InputWrapper>
          {data && data.length > 0 && (
            <Controller
              name="serviceChargeOption"
              control={control}
              render={({ field }) => (
                <Select
                  className="mb-3"
                  title={intl.formatMessage({
                    defaultMessage: 'Доп.расход за выкуп',
                    id: 'purchaseOrderModal.input-label.additionalService',
                    description: 'Заголовок поля ввода',
                  })}
                  placeholder={intl.formatMessage({
                    defaultMessage: 'Выберите причину/тип выкупа',
                    id: 'purchaseOrderModal.input-placeholder.selectReason',
                    description: 'Описание поля ввода',
                  })}
                  options={
                    data?.map(({ id, name }) => ({
                      value: id,
                      label: name,
                    })) ?? []
                  }
                  {...field}
                  error={errors.serviceChargeOption?.message}
                />
              )}
            />
          )}

          <InputWrapper
            title={intl.formatMessage({
              defaultMessage: 'Ссылка на ордер',
              id: 'purchaseOrderModal.input-label.orderLink',
              description: 'Заголовок поля ввода',
            })}
            className="mb-3"
            error={errors.orderLink?.message}
          >
            <div className="input-group">
              <input className="form-control" {...register('orderLink')} />
            </div>
          </InputWrapper>
          <InputWrapper
            title={intl.formatMessage({
              defaultMessage: 'Номер ордера',
              id: 'purchaseOrderModal.input-label.orderNumber',
              description: 'Заголовок поля ввода',
            })}
            className="mb-3"
            error={errors.orderNumber?.message}
          >
            <div className="input-group">
              <input
                className="form-control"
                type="number"
                {...register('orderNumber', {
                  ...intValidation,
                })}
              />
            </div>
          </InputWrapper>
          <InputWrapper
            title={intl.formatMessage({
              defaultMessage: 'Аккаунт',
              id: 'purchaseOrderModal.input-label.orderAccount',
              description: 'Заголовок поля ввода',
            })}
            className="mb-3"
            error={errors.orderAccount?.message}
          >
            <div className="input-group">
              <input className="form-control" {...register('orderAccount')} />
            </div>
          </InputWrapper>
          <InputWrapper
            title={intl.formatMessage({
              defaultMessage: '№ ТТН',
              id: 'purchaseOrderModal.input-label.landingBill',
              description: 'Заголовок поля ввода',
            })}
            className="mb-3"
            error={errors.landingBill?.message}
          >
            <div className="input-group">
              <input className="form-control" {...register('landingBill')} />
            </div>
          </InputWrapper>
          <InputWrapper
            title={intl.formatMessage({
              defaultMessage: 'Название транспортной компании',
              id: 'purchaseOrderModal.input-label.transportCompany',
              description: 'Заголовок поля ввода',
            })}
            className="mb-3"
            error={errors.transportCompany?.message}
          >
            <div className="input-group">
              <input
                className="form-control"
                {...register('transportCompany')}
              />
            </div>
          </InputWrapper>
        </div>
        <div className="modal-footer justify-content-end">
          <button
            type="submit"
            className="btn btn-success"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Подтвердить выкуп"
                id="purchaseOrderPage.status-label.confirmRedemption"
                description="Надпись на кнопке"
              />
            )}
          </button>
        </div>
      </form>
    </div>
  );
};
