import { Header } from '../../common/header.component';
import { Input } from '../../common/input.component';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import { Select } from '../../common/select.component';
import { AddButton } from '../../common/add-button';
import { DeliveryMethodService } from '../../../services/deliveryMethod.service';
import { AlertService } from '../../../services/alert.service';
import { useNavigate, useLocation } from 'react-router-dom';
import { number, string, object, ref, array } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import {
  useDeliveryMethods,
  useOrderCategories,
} from '../../../consts/db-values';
import { CleanDeliveryMethod } from '../../../export-types/cleaned-types';
import { required } from '../../../utils/validations';
import { useSuppliers } from '../../../api/suppliers';
import { useLocations } from '../../../api/locations';

const transformNumber = (value: any) => {
  const parsed = parseFloat(value);
  return isNaN(parsed) ? undefined : parsed;
};
const floatNumber = number().transform(transformNumber);
const integerNumber = floatNumber.integer();
const integerNumberRequired = integerNumber.required('Заполните поле');
const stringRequired = string().required('Заполните поле');

type Tariff = {
  price: string;
  densityTo: string;
  densityFrom: string;
};

const TariffSchema = object().shape({
  price: floatNumber.required('Заполните поле'),
  densityTo: integerNumber.moreThan(ref('densityFrom')),
  densityFrom: integerNumberRequired.test({
    name: 'densityFrom',
    skipAbsent: true,
    message: ({ index }) =>
      `Плотность в этом тарифе пересекается с другим интервалом (№${index})`,
    test: (valueFrom, context) => {
      const tariffs = context.from![1].value.tariffs;
      const fromTo = tariffs.map((item: Tariff, index: number) => {
        const from = parseFloat(item.densityFrom);
        const to = parseFloat(item.densityTo);

        return {
          index,
          from,
          to: isNaN(to) ? Infinity : to,
        };
      });
      const current = context.parent;
      const valueTo = current.densityTo || Infinity;
      // @ts-ignore context.options.index есть в InternalOptions
      const currentIndex = context.options.index;
      for (const { index, from, to } of fromTo) {
        if (index === currentIndex) {
          continue;
        }
        if (valueFrom < to && valueTo > from) {
          return context.createError({ params: { index: index + 1 } });
        }
      }
      return true;
    },
  }),
});
const FormSchema = object().shape({
  method: stringRequired,
  name: stringRequired,
  deliveryPeriodFrom: integerNumberRequired,
  deliveryPeriodTo: integerNumber.moreThan(ref('deliveryPeriodFrom')),
  productCategories: array(string()).min(1, 'Заполните поле'),
  tariffs: array().of(TariffSchema),
  supplierId: integerNumber,
  destinationId: integerNumber,
});

export const DeliveryMethodScreen = () => {
  const { data: suppliers } = useSuppliers();
  const { data: locations } = useLocations();
  const DELIVERY_METHODS = useDeliveryMethods();
  const ORDER_CATEGORIES = useOrderCategories();

  const deliveryMethods = Object.keys(DELIVERY_METHODS).map((key) => ({
    label: DELIVERY_METHODS[key as keyof typeof DELIVERY_METHODS],
    value: key,
  }));

  const categories = Object.keys(ORDER_CATEGORIES).map((key) => ({
    label: ORDER_CATEGORIES[key as keyof typeof ORDER_CATEGORIES],
    value: key,
  }));

  const navigate = useNavigate();
  const { state } = useLocation() as {
    state?: CleanDeliveryMethod;
  };

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    // @ts-ignore
    defaultValues: {
      ...{
        tariffs: [
          {
            densityFrom: '',
            densityTo: '',
            price: '',
          },
        ],
      },
      ...state,
      supplierId: state?.supplier?.id,
      destinationId: state?.destination?.id,
    },
    // @ts-ignore
    resolver: yupResolver(FormSchema),
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'tariffs',
  });

  function onSave(deliveryMethod: unknown) {
    const promise = state?.id
      ? DeliveryMethodService.updateDeliveryMethod(deliveryMethod, state.id)
      : DeliveryMethodService.createDeliveryMethod(deliveryMethod);
    promise.then(() => {
      AlertService.success();
      navigate('/deliveries/delivery');
    });
  }

  const isEdit = !!state;

  return (
    <>
      <Header />
      <div className="container" style={{ marginTop: -70 }}>
        <h1 className="mb-4">
          {isEdit ? 'Изменить' : 'Добавить'} способ доставки
        </h1>
        <form onSubmit={handleSubmit(onSave)}>
          <div className="row">
            <Controller
              name="supplierId"
              control={control}
              rules={required}
              render={({ field }) => (
                <Select
                  className="mb-3"
                  title="Поставщик"
                  placeholder="Выберите поставщика"
                  options={
                    suppliers?.map(({ id, name, location }) => ({
                      value: id,
                      label:
                        name +
                        (location?.name ? ` (откуда: ${location.name})` : ''),
                    })) ?? []
                  }
                  {...field}
                  error={errors.supplierId?.message}
                />
              )}
            />
            <Controller
              name="destinationId"
              control={control}
              rules={required}
              render={({ field }) => (
                <Select
                  className="mb-3"
                  title="Куда"
                  placeholder="Выберите локацию"
                  options={
                    locations?.map(({ id, name }) => ({
                      value: id,
                      label: name,
                    })) ?? []
                  }
                  {...field}
                  error={errors.destinationId?.message}
                />
              )}
            />
            <div className="col-12 mb-3">
              <Controller
                name="method"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={deliveryMethods}
                    title="Способ доставки"
                    //defaultValue={state?.method && deliveryMethods.find(({ value }) => value === state.method)}
                    placeholder="Способ доставки"
                    error={errors.method?.message}
                  />
                )}
              />
            </div>
            <div className="col-6 mb-3">
              <Input
                title="Название тарифа"
                placeholder="Например, Авиа (Экспресс)"
                {...register('name')}
                error={errors.name?.message}
              />
              <div className="fs-14 color-gray-400">
                Это название отобразится у клиента
              </div>
            </div>
            <div className="col-6 mb-3">
              <div className="mb-2">Срок доставки</div>
              <div className="d-flex flex-row align-items-center">
                <Input
                  className="flex-grow-1"
                  type="number"
                  min={0}
                  step={1}
                  {...register('deliveryPeriodFrom')}
                  error={errors.deliveryPeriodFrom?.message}
                  postfix="дней"
                />
                <div className="ps-2 pe-2">—</div>
                <Input
                  className="flex-grow-1"
                  type="number"
                  min={0}
                  step={1}
                  {...register('deliveryPeriodTo')}
                  error={errors.deliveryPeriodTo?.message}
                  postfix="дней"
                />
              </div>
              <div className="fs-14 color-gray-400">
                Точное количество дней или диапазон
              </div>
            </div>
            <div className="col-12 mb-3">
              <Controller
                name="productCategories"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    isMulti
                    options={categories}
                    defaultValue={
                      state?.productCategories &&
                      categories.filter(({ value }) =>
                        state.productCategories!.includes(value),
                      )
                    }
                    title="Разрешенные категории товаров"
                    placeholder="Разрешенные категории товаров"
                    error={errors.productCategories?.message}
                  />
                )}
              />
            </div>
            <h3>Тарифная сетка</h3>
            {fields.map((field, index) => (
              <div className="col-12 mb-3" key={field.id}>
                <div className="row align-items-start">
                  <div className="col-8">
                    <div className="mb-2">№{index + 1} Плотность груза</div>
                    <div className="d-flex flex-row align-items-center">
                      <Input
                        className="flex-grow-1"
                        {...register(`tariffs.${index}.densityFrom`)}
                        type="number"
                        min={0}
                        step={0.01}
                        error={errors.tariffs?.[index]?.densityFrom?.message}
                        postfix="кг / м³"
                      />
                      <div className="ps-2 pe-2">—</div>
                      <Input
                        className="flex-grow-1"
                        {...register(`tariffs.${index}.densityTo`)}
                        type="number"
                        min={0}
                        step={0.01}
                        error={errors.tariffs?.[index]?.densityTo?.message}
                        postfix="кг / м³"
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="mb-2">Стоимость</div>
                    <div className="d-flex flex-row align-items-center">
                      <Input
                        className="flex-grow-1"
                        {...register(`tariffs.${index}.price`)}
                        type="number"
                        min={0}
                        step={0.01}
                        error={errors.tariffs?.[index]?.price?.message}
                        postfix="$ / кг"
                      />
                      {index > 0 ? (
                        <i
                          className="bi bi-trash p-2 cursor-pointer hover-red"
                          onClick={() => remove(index)}
                        />
                      ) : (
                        <div style={{ width: 31 }} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="col-12">
              <AddButton
                onClick={() =>
                  // @ts-ignore
                  append({ price: '', densityTo: '', densityFrom: '' })
                }
              >
                Добавить тариф
              </AddButton>
            </div>
            <div className="col-12 pt-3 mb-4">
              <button type="submit" className="btn btn-success w-100">
                Сохранить
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
