import useSWR from 'swr';
import { fetcher } from '../services/api';
import {
  CleanDeliveryMethod,
  CleanDeliveryOrderDeliveryMarkup,
  CleanLocalDeliveryMethod,
  CleanPackageMethod,
} from '../export-types/cleaned-types';

export type OrderGroup = {
  orders: string[];
};

export function useDeliveryMarkup() {
  const { data, isLoading, mutate } = useSWR<
    CleanDeliveryOrderDeliveryMarkup[]
  >('/delivery-markup', fetcher);

  return { data, isLoading, mutate };
}

export function useLocalDeliveryMethods() {
  const { data, isLoading, mutate } = useSWR<CleanLocalDeliveryMethod[]>(
    '/local-delivery-method',
    fetcher,
  );
  return { data, isLoading, mutate };
}

export function useStorageLocation() {
  const { data, isLoading, mutate } = useSWR<string[]>(
    `/supplier/storage-locations`,
    fetcher,
  );
  return { data, isLoading, mutate };
}

export function useDeliveriesMethod(purchaseOrders: string[]) {
  const { data, isLoading, mutate } = useSWR<{
    delivery: CleanDeliveryMethod[];
  }>(
    {
      url: `/delivery-order/methods`,
      params: {
        purchaseOrders: purchaseOrders,
      },
    },
    fetcher,
  );
  return { data, isLoading, mutate };
}

export function usePackageMethods(groups: OrderGroup[]) {
  const { data, isLoading, mutate } = useSWR(
    () => {
      return groups
        .map((group) => group.orders)
        .map((purchaseOrders) => ({
          url: `/delivery-order/package-methods`,
          params: {
            purchaseOrders: purchaseOrders,
          },
        }));
    },
    (keys) =>
      Promise.all<CleanPackageMethod[]>(keys.map((key) => fetcher(key))),
  );
  return { data, isLoading, mutate };
}
