import React from 'react';
import { useForm } from 'react-hook-form';
import { api } from '../../../services/api';
import { InputWrapper } from '../../common/input-wrapper';
import { required } from '../../../utils/validations';
import { FormattedMessage, useIntl } from 'react-intl';
import { useModalClose } from '../../../utils/useModalClose';

type PurchaseOrderFreezeManyModalProps = {
  close: () => void;
  reject: () => void;
  orderIds: string[];
};

export const PurchaseOrderFreezeManyModal: React.FC<
  PurchaseOrderFreezeManyModalProps
> = ({ close, orderIds, reject }) => {
  const intl = useIntl();
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useForm<
    | {
        reason:
          | 'incorrectOrder'
          | 'underpricing'
          | 'sellerProblem'
          | 'productProblem'
          | 'supplierNegotiations';
        reasonDetails: undefined;
      }
    | {
        reason: 'other';
        reasonDetails: string;
      }
  >();

  const onSubmit = handleSubmit((data) => {
    let textReason = data.reasonDetails;

    switch (data.reason) {
      case 'incorrectOrder':
        textReason = intl.formatMessage({
          defaultMessage: 'Не правильно оформленные заказы',
          id: 'purchaseOrderFreezeModal.select-label.incorrectOrder',
          description: 'Выбор причины заморозки',
        });
        break;
      case 'underpricing':
        textReason = intl.formatMessage({
          defaultMessage: 'Занижение цены',
          id: 'purchaseOrderFreezeModal.select-label.underpricing',
          description: 'Выбор причины заморозки',
        });
        break;
      case 'sellerProblem':
        textReason = intl.formatMessage({
          defaultMessage: 'Проблема с продавцом товара',
          id: 'purchaseOrderFreezeModal.select-label.sellerProblem',
          description: 'Выбор причины заморозки',
        });
        break;
      case 'productProblem':
        textReason = intl.formatMessage({
          defaultMessage: 'Проблема с товарами при выкупе',
          id: 'purchaseOrderFreezeModal.select-label.problemWithGoodsAtRedemption',
          description: 'Выбор причины заморозки',
        });
        break;
      case 'supplierNegotiations':
        textReason = intl.formatMessage({
          defaultMessage: 'Переговоры с поставщиком',
          id: 'purchaseOrderFreezeModal.select-label.negotiationsWithSupplier',
          description: 'Выбор причины заморозки',
        });
        break;
      default:
        ((reason: 'other') => void 0)(data.reason);
    }

    api
      .post(`/purchase-order/freezeOrders`, {
        ids: orderIds,
        reason: textReason,
      })
      .then(() => {
        close();
      });
  });

  const modalRef = useModalClose(reject);

  return (
    <div className="modal-dialog mw-400" ref={modalRef}>
      <div className="modal-content">
        <div className="modal-header border-bottom-0 p-4">
          <button
            type="button"
            className="btn-close"
            onClick={() => reject()}
          />
        </div>
        <form className="modal-body pt-2 p-4" onSubmit={onSubmit}>
          <div className="text-center mb-4 mx-auto">
            <i className="bi bi-exclamation-circle-fill color-yellow fs-65 lh-1" />
            <h3 className="mt-3">
              <FormattedMessage
                defaultMessage="Вы хотите заморозить заказы"
                id="purchaseOrderFreezeModal.select-label.wantFreezeOrder"
                description="Заголовок в форме заморозки"
              />
            </h3>
            <div className="mt-3">
              <FormattedMessage
                defaultMessage="Укажите причину заморозки"
                id="purchaseOrderFreezeModal.select-label.specifyTheCauseOfTheFreeze"
                description="Подзаголовок в форме заморозки"
              />
            </div>
          </div>

          <InputWrapper
            className="mb-3"
            title={intl.formatMessage({
              defaultMessage: 'Причина заморозки',
              id: 'purchaseOrderFreezeModal.select-label.reasonFreezing',
              description: 'Заголовок поля ввода',
            })}
            required
            error={errors.reason?.message}
          >
            <select
              className="form-select"
              {...register('reason', {
                ...required,
              })}
            >
              <option value="" hidden>
                <FormattedMessage
                  defaultMessage="Выберите причину"
                  id="purchaseOrderFreezeModal.select-label.chooseAReason"
                  description="Заголовок выпадающего окна"
                />
              </option>
              <option value="incorrectOrder">
                <FormattedMessage
                  defaultMessage="Неправильно оформленные заказы"
                  id="purchaseOrderFreezeModal.select-label.incorrectOrder"
                  description="Выбор причины заморозки"
                />
              </option>
              <option value="underpricing">
                <FormattedMessage
                  defaultMessage="Занижение цены"
                  id="purchaseOrderFreezeModal.select-label.underpricing"
                  description="Выбор причины заморозки"
                />
              </option>
              <option value="sellerProblem">
                <FormattedMessage
                  defaultMessage="Проблема с продавцом товаров"
                  id="purchaseOrderFreezeModal.select-label.sellerProblem"
                  description="Выбор причины заморозки"
                />
              </option>
              <option value="productProblem">
                <FormattedMessage
                  defaultMessage="Проблема с товарами при выкупе"
                  id="purchaseOrderFreezeModal.select-label.problemWithGoodsAtRedemption"
                  description="Выбор причины заморозки"
                />
              </option>
              <option value="supplierNegotiations">
                <FormattedMessage
                  defaultMessage="Переговоры с поставщиком"
                  id="purchaseOrderFreezeModal.select-label.negotiationsWithSupplier"
                  description="Выбор причины заморозки"
                />
              </option>
              <option value="other">
                <FormattedMessage
                  defaultMessage="Другое"
                  id="purchaseOrderFreezeModal.select-label.other"
                  description="Выбор причины заморозки"
                />
              </option>
            </select>
          </InputWrapper>
          {watch('reason') === 'other' && (
            <InputWrapper
              className="mb-5"
              error={errors.reasonDetails?.message}
            >
              <textarea
                className="form-control"
                {...register('reasonDetails', {
                  ...required,
                })}
              />
            </InputWrapper>
          )}
          <button className="btn btn-success w-100">
            <FormattedMessage
              defaultMessage="Заморозить заказы"
              id="purchaseOrderFreezeModal.btn-label.freezeOrder"
              description="Надпись на кнопке"
            />
          </button>
        </form>
      </div>
    </div>
  );
};
