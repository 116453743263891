import { CleanMessage } from '../../export-types/cleaned-types';
import React, { useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { Gallery } from './gallery/gallery';
import { getFileV2ApiUrl } from '../../utils/common.utils';
import PdfIcon from '../../assets/pdf-file.svg';
import { api } from '../../services/api';
import { ModalsService } from '../../services/modals.service';
import { ConfirmModal } from './confirm-modal';
import { ConversationTab } from './conversation-window';

type MessageCardProps = {
  self: boolean;
  innerRef?: (element: any) => void;
  message: CleanMessage;
  tab: ConversationTab;
  onDelete?: () => void;
};

export const MessageCard = (props: MessageCardProps) => {
  const [isOriginalMessage, setIsOriginalMessage] = useState<boolean>(true);
  const [isTranslating, setIsTranslating] = useState<boolean>(false);
  const formatMessage = (message: string) => {
    const lines = message.trim().split('\n');
    return lines.map((line, index) => (
      <React.Fragment key={index}>
        {line}
        {index !== lines.length - 1 && <br />}
      </React.Fragment>
    ));
  };

  const deleteMessage = async () => {
    await ModalsService.createModal(ConfirmModal, {
      title: 'Удалить сообщение',
      buttonText: 'Удалить',
    });
    await api.delete(`/conversation/messages/${props.message.id}`);
    props.onDelete && props.onDelete();
  };
  const translateMessage = async () => {
    if (!props.message.translatedMessage) {
      setIsTranslating(true);
      await api
        .post(`/conversation/messages/${props.message.id}/translate`)
        .then(({ data }) => {
          if (data.translatedMessage) {
            props.message.translatedMessage = data.translatedMessage;
            setIsOriginalMessage(false);
          }
        })
        .finally(() => setIsTranslating(false));
    } else {
      setIsOriginalMessage((prevState) => !prevState);
    }
  };

  const displayedMessage =
    isOriginalMessage || null === props.message.translatedMessage
      ? props.message.message
      : props.message.translatedMessage;

  return (
    <div
      ref={props.innerRef}
      className={classNames(
        'rounded mx-3 my-1 align-self-start',
        props.self ? 'bg-green-200 align-self-end' : 'bg-gray align-self-start',
      )}
    >
      <div className="d-flex flex-column gap-0.5 m-2">
        <div className="d-flex flex-row gap-2 ">
          <div className="fw-500">{props.message.user.name}</div>
          <div className="color-gray-400">
            {moment(props.message.sentAt).format('HH:mm')}
          </div>
          <div>
            {props.message.read ? (
              <i className="bi bi-check-all color-green" />
            ) : (
              <i className="bi bi-check color-gray-400" />
            )}
          </div>
          {props.tab === 'supplier' &&
            (isTranslating ? (
              <span className="spinner-border spinner-border-sm" />
            ) : (
              <div onClick={translateMessage}>
                <i className="bi bi-translate color-gray-400" />
              </div>
            ))}

          {props.self && (
            <div onClick={() => deleteMessage()}>
              <i className="bi bi-trash color-gray-400" />
            </div>
          )}
          <div></div>
        </div>
        <div>{formatMessage(displayedMessage)}</div>
        {props.message.attachments.length > 0 ? (
          <div>
            <Gallery className="mt-2">
              {props.message.attachments.map((file, index) => (
                <a
                  href={getFileV2ApiUrl(file.id)}
                  key={index}
                  data-iframe={file.type === 'pdf'}
                >
                  <img
                    className="rounded fit-cover me-2"
                    src={
                      file.type === 'pdf'
                        ? PdfIcon
                        : getFileV2ApiUrl(file.id, '64x64')
                    }
                    width={64}
                    height={64}
                    alt=""
                  />
                </a>
              ))}
            </Gallery>
          </div>
        ) : null}
      </div>
    </div>
  );
};
