import React from 'react';
import moment from 'moment';
import { getDeliveryMethodIcon } from '../../../utils/common.utils';
import Whisper from 'rsuite/Whisper';
import Tooltip from 'rsuite/Tooltip';
import { FormattedMessage } from 'react-intl';

type DeliveryPeriodCellProps = {
  deliveryMethod: {
    method: string;
    deliveryPeriodTo: number | null;
  };
  sentDate: string;
};

export const DeliveryPeriodCell: React.FC<DeliveryPeriodCellProps> = ({
  deliveryMethod,
  sentDate,
}) => {
  const deliveryDate = moment(sentDate)
    .add(deliveryMethod.deliveryPeriodTo, 'd')
    .format('DD.MM.YYYY');

  const deliveryPeriodTooltip = (
    <Tooltip className="fs-14 text-start lh-sm p-2" style={{ maxWidth: 293 }}>
      Указана ориентировочная дата получения. В случае осуществления таможенной
      проверки груза, срок доставки может быть увеличен до 92 дней.
    </Tooltip>
  );

  return (
    <>
      <div className="col-auto">
        <div className="color-gray-800 fs-16 fw-500">
          {moment(sentDate).format('DD.MM.YYYY')}
        </div>
        <div className="color-gray-600 fs-14">
          <FormattedMessage
            defaultMessage="Отправлено в РФ"
            id="order.status-label.shippedToRussianFederation"
            description="Статус заказа"
          />
        </div>
      </div>
      <img
        src={getDeliveryMethodIcon(deliveryMethod.method)}
        className="me-4 ms-4"
        alt=""
      />
      <div className="col-auto">
        <div className="color-gray-800 fs-16 fw-500 d-flex">
          ~ {deliveryDate}
          <Whisper
            placement="right"
            trigger="hover"
            speaker={deliveryPeriodTooltip}
          >
            <i className="ms-2 bi bi-question-circle" />
          </Whisper>
        </div>
        <div className="color-gray-600 fs-14">
          <FormattedMessage
            defaultMessage="Ожидается на складе"
            id="delivery.status-label.expectedInWarehouse"
            description="Статус доставки"
          />
        </div>
      </div>
    </>
  );
};
