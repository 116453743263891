import React from 'react';
import { Header } from '../../common/header.component';
import { AlertService } from '../../../services/alert.service';
import { useNavigate } from 'react-router-dom';
import {
  Control,
  Controller,
  FieldPath,
  FieldPathValue,
  useForm,
  UseFormRegister,
  useFormState,
} from 'react-hook-form';
import { required } from '../../../utils/validations';
import { InputWrapper } from '../../common/input-wrapper';
import { api } from '../../../services/api';
import { uploadMediaInputFiles } from '../../../services/file-service';
import { MediaInput, MediaInputValue } from '../../common/media-input';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { User, UserSelect } from '../../common/user-select';
import get from 'lodash/get';
import { Select } from '../../common/select.component';
import { useSuppliers } from '../../../api/suppliers';

export const AdminParcelCreateScreen: React.FC = () => {
  return (
    <>
      <Header />
      <div className="container" style={{ marginTop: -10 }}>
        <h1 className="mb-4">Добавить ожидаемую посылку на склад в Китае</h1>
        <div className="color-gray-400 fs-16 lh-sm">
          Отправьте заказ на наш склад и заполните форму. После получения
          товара, мы его проверим и вы сможете отправить его в Россию или
          объединить с другими товарами.
        </div>
        <ParcelForm />
      </div>
    </>
  );
};

type ParcelFormData = {
  parcel: {
    name: string;
    trackNumber: string;
    requestCount: string;
    productPrice: string;
    comment: string;
    requestFiles: MediaInputValue[];
  };
  user: User;
  supplierId: number;
};

type ParcelFormProps = {
  defaultValues?: Partial<ParcelFormData>;
};

const ParcelForm = (props: ParcelFormProps) => {
  const { data: suppliers } = useSuppliers();
  const {
    register,
    control,
    formState: { isSubmitting, errors },
    watch,
    handleSubmit,
  } = useForm<ParcelFormData>({
    defaultValues: props.defaultValues,
  });

  const navigate = useNavigate();
  const watchUser = watch('user');

  async function onSubmit(form: ParcelFormData) {
    console.log(form);
    const requestFilesResponse = await uploadMediaInputFiles(
      form.parcel.requestFiles,
    );

    const {
      data: { parcel },
    } = await api.post('/parcels/admin', {
      userId: form.user.id,
      name: form.parcel.name.trim(),
      trackNumber: form.parcel.trackNumber.trim(),
      requestCount: Number(form.parcel.requestCount),
      productPrice: form.parcel.productPrice,
      comment: form.parcel.comment.trim(),
      requestFiles: requestFilesResponse.map((response) => ({
        id: response.file.id,
      })),
      supplierId: form.supplierId,
    });

    AlertService.success(
      <div>
        <div className="fw-bold">Заказ {parcel.internalId} создан</div>
        <div className="fs-14">
          За всеми изменениями по заказу вы можете наблюдать в разделе “Выкупы”.
        </div>
      </div>,
    );

    navigate(-1);
  }

  return (
    <form
      className="row justify-content-center pt-4"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="col-7">
        <Controller
          name="supplierId"
          control={control}
          rules={required}
          render={({ field }) => (
            <Select
              className="mb-3"
              title="Поставщик"
              placeholder="Выберите поставщика"
              options={
                suppliers?.map(({ id, name, location }) => ({
                  value: id,
                  label:
                    name +
                    (location?.name ? ` (откуда: ${location.name})` : ''),
                })) ?? []
              }
              {...field}
              error={errors.supplierId?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="user"
          rules={{
            ...required,
          }}
          render={({ field, fieldState }) => (
            <InputWrapper
              className="mb-3"
              title="Клиент"
              required
              error={fieldState.error?.message}
            >
              <UserSelect placeholder="Клиент" {...field} />
            </InputWrapper>
          )}
        />
        <div className="mb-4">
          <ParcelItemForm register={register} control={control} path="parcel" />
        </div>
      </div>
      <div className="col-5">
        <div className="sticky-top ">
          <div className="bg-gray-50 p-4">
            <h3 className="fs-21 fw-500">Ваш адрес в г. Гуанчжоу</h3>
            <div className="color-gray-400 fs-14 lh-sm">
              Укажите этот адрес в форме заказа товара на сайте или отправьте
              поставщику
            </div>

            <div className="mt-3 mb-4">
              <div className="mb-3">
                地址: 广州市越秀区荔德路318号汇富商贸中心A28-103
              </div>
              <div className="mb-3">
                收件人: ID{watchUser ? watchUser.internalId : ''}
              </div>
              <div className="mb-3">电话: 185 0080 6599</div>
              <div>
                一定要在箱子上面写 "ID{watchUser ? watchUser.internalId : ''}"
              </div>
            </div>
            <button
              type="submit"
              className="btn btn-success w-100"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                'Сохранить'
              )}
            </button>
            <div className="fs-14 color-gray-400 lh-sm mt-3">
              Размещая заказ, вы соглашаетесь с правилами пользования сервисом
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

type ParcelItemValue = {
  name: string;
  trackNumber: string;
  requestCount: string;
  productPrice: string;
  comment: string;
  requestFiles: MediaInputValue[];
};

type ParcelItemFormProps<TFieldValues extends FieldValues, TContext = any> = {
  register: UseFormRegister<TFieldValues>;
  control: Control<TFieldValues, TContext>;
  path: keyof {
    [P in FieldPath<TFieldValues> as FieldPathValue<
      TFieldValues,
      P
    > extends ParcelItemValue
      ? P
      : never]: P;
  };
};

const ParcelItemForm = <TFieldValues extends FieldValues, TContext = any>(
  props: ParcelItemFormProps<TFieldValues, TContext>,
) => {
  const { control, register } = props;
  const { errors } = useFormState({ control });

  return (
    <div className="card border-0 rounded">
      <div className="card-header bg-gray-50 border-0 p-3">
        <InputWrapper
          error={
            // @ts-ignore todo
            get(errors, props.path)?.name?.message
          }
        >
          <div className="row g-1">
            <div className="col">
              <input
                type="text"
                placeholder="Например, чехлы для телефонов"
                className="form-control"
                {...register(
                  `${String(props.path)}.name` as FieldPath<TFieldValues>,
                )}
              />
            </div>
          </div>
          <div className="fs-14 color-gray-400 mt-1">
            Вы можете указать свое название, так будет проще найти заказ
          </div>
        </InputWrapper>
      </div>

      <div className="card-body border border-gray-200 border-top-0 rounded-bottom">
        <InputWrapper
          title="Трек-номер"
          className="mb-4"
          error={
            // @ts-ignore todo
            get(errors, props.path)?.trackNumber?.message
          }
          required
        >
          <input
            className="form-control"
            type="text"
            {...register(
              `${String(props.path)}.trackNumber` as FieldPath<TFieldValues>,
              {
                ...required,
              },
            )}
          />
        </InputWrapper>
        <InputWrapper
          title="Количество товаров"
          className="mb-4"
          error={
            // @ts-ignore todo
            get(errors, props.path)?.requestCount?.message
          }
          required
        >
          <div className="input-group">
            <input
              className="form-control"
              type="number"
              min={1}
              step={1}
              {...register(
                `${String(props.path)}.requestCount` as FieldPath<TFieldValues>,
                {
                  ...required,
                },
              )}
            />
            <span className="input-group-text">шт</span>
          </div>
        </InputWrapper>
        <InputWrapper
          title="Стоимость товаров"
          className="mb-4"
          error={
            // @ts-ignore todo
            get(errors, props.path)?.productPrice?.message
          }
          required
        >
          <div className="input-group">
            <input
              className="form-control"
              type="number"
              min={1}
              step={0.01}
              {...register(
                `${String(props.path)}.productPrice` as FieldPath<TFieldValues>,
                {
                  ...required,
                },
              )}
            />
            <span className="input-group-text">¥</span>
          </div>
          <div className="fs-14 color-gray-400 mt-1">
            Необходима для расчёта страховки при оформлении доставки.
          </div>
        </InputWrapper>
        <Controller
          control={control}
          rules={{ ...required }}
          name={`${String(props.path)}.requestFiles` as FieldPath<TFieldValues>}
          render={({ field, fieldState }) => (
            <InputWrapper
              title="Фотографии товаров"
              className="mb-4"
              required
              error={fieldState.error?.message}
            >
              <MediaInput {...field} multiple />
            </InputWrapper>
          )}
        />
        <InputWrapper title="Комментарий" className="mb-3">
          <textarea
            placeholder="Укажите здесь дополнительную информацию по товару"
            className="form-control fs-16 lh-sm"
            {...register(
              `${String(props.path)}.comment` as FieldPath<TFieldValues>,
            )}
          />
        </InputWrapper>
      </div>
    </div>
  );
};
