import React from 'react';
import { getLoader } from './user-select';
import AsyncSelect from 'react-select/async';

export type User = {
  id: string;
  internalId: number | null;
  phone?: string;
  name?: string | null;
};

type UserSelectProps = {
  onChange?: (data: User[]) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  name: string;
  placeholder?: string;
  value: User[];
  roles?: string[];
};

export const UserMultiSelect = React.forwardRef(function (
  props: UserSelectProps,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  return (
    <AsyncSelect
      name={props.name}
      placeholder={props.placeholder}
      onBlur={props.onBlur}
      loadOptions={getLoader(props.roles ?? ['client'])}
      cacheOptions
      defaultOptions
      getOptionLabel={(option) =>
        option.internalId
          ? `ID ${option.internalId} / ${option.phone} / ${option.name}`
          : `${option.phone} / ${option.name}`
      }
      getOptionValue={(option) => option.id}
      isMulti={true}
      onChange={(users) =>
        props.onChange ? props.onChange(users.concat()) : undefined
      }
      value={props.value}
      // @ts-ignore
      ref={ref}
    />
  );
});
